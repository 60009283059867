/*? FULL */
.km-1 {
  margin: 1rem !important;
}

.km-2 {
  margin: 2rem !important;
}

.km-3 {
  margin: 3rem !important;
}

.km-4 {
  margin: 4rem !important;
}

.km-5 {
  margin: 5rem !important;
}

/*? MARGIN TOP */
.km-t-1 {
  margin-top: 1rem !important;
}

.km-t-2 {
  margin-top: 2rem !important;
}

.km-t-3 {
  margin-top: 3rem !important;
}

.km-t-4 {
  margin-top: 4rem !important;
}

.km-t-5 {
  margin-top: 5rem !important;
}

/*? MARGIN RIGHT */
.km-r-1 {
  margin-top: 1rem !important;
}

.km-r-2 {
  margin-top: 2rem !important;
}

.km-r-3 {
  margin-top: 3rem !important;
}

.km-r-4 {
  margin-top: 4rem !important;
}

.km-r-5 {
  margin-top: 5rem !important;
}

/*? MARGIN BOTTOM */
.km-b-1 {
  margin-bottom: 1rem !important;
}

.km-b-2 {
  margin-bottom: 2rem !important;
}

.km-b-3 {
  margin-bottom: 3rem !important;
}

.km-b-4 {
  margin-bottom: 4rem !important;
}

.km-b-5 {
  margin-bottom: 5rem !important;
}

/*? MARGIN LEFT */
.km-l-1 {
  margin-bottom: 1rem !important;
}

.km-l-2 {
  margin-bottom: 2rem !important;
}

.km-l-3 {
  margin-bottom: 3rem !important;
}

.km-l-4 {
  margin-bottom: 4rem !important;
}

.km-l-5 {
  margin-bottom: 5rem !important;
}