
.ch-details-wrapper {
  display: flex;
  column-gap: 2rem;
}

.edit-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: var(--violet-600);
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}
