
* {
  font-family: CraftworkSans, serif;
  font-style: normal;
  line-height: 22px;
  color: var(--gray-600);
  font-weight: 600;
}

.custom__container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  margin: 2rem 0;
}

.object__details_layout {
  max-width: 40rem;
  //max-height: 320px;
  width: 100%;
  height: auto;
  margin-right: 3rem;

  &_img {
    width: 100%;
    height: 84%;
    min-height: 20rem;
    overflow: hidden;
    margin-bottom: 1.5rem;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: 2px solid var(--gray-200);
      border-radius: 2rem;
    }
  }

  button {
    width: 100%;
    height: 56px;
    border-radius: 32px !important;
    border: none !important;
    font-size: 16px;
    font-weight: 600;
    background: #f3f4f6 !important;
    color: #4b5563 !important;

    svg {
      margin-right: 18px;
    }
  }
}

button {
  width: 100%;
  height: 56px;
  border-radius: 32px !important;
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  background: #f3f4f6 !important;
  color: #4b5563 !important;

  svg {
    margin-right: 18px;
  }
}

.object__details_info {
  &_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  &_card {
    display: flex;
    align-items: center;
    width: 48%;
    height: 56px;
    padding: 0 16px;
    margin-bottom: 1.5rem;
    border: 2px solid #e5e7eb;
    border-radius: 32px;
    letter-spacing: 1px;

    &:nth-child(odd):last-child {
      width: 100%;
    }

    &_text {
      width: 100%;
      padding-left: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;

      & > span {
        display: block;
        text-transform: uppercase;
      }

      & > span:first-child {
        font-size: 0.75rem;
      }
    }
  }

  .breadcrumb__head {
    font-size: 24px;
    line-height: 28px;
    color: var(--gray-600);
    text-transform: uppercase;

    .slash {
      color: var(--gray-400);
    }
  }

  .breadcrumb__location {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-400);
  }
}

.lowercase {
  text-transform: lowercase;
}

.font-normal {
  font-size: 1rem;
}

.go__back {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

::v-deep .yandex__content {
  width: 45rem;
  height: 25rem;

  &-map {
    z-index: 100;
    border-radius: 2rem;
    border: 2px solid var(--gray-200);
    height: 25rem;
    width: 45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

.parking__layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  .card {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    margin-bottom: 1.5rem;
    border: 2px solid #e5e7eb;
    border-radius: 32px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1100px) {
  .object__details_info {
    &_cards {
      flex-direction: column;
    }

    &_card {
      width: 100%;
    }
  }
}
