
.ch-calculator-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 10px;
}
.full {
  grid-column: span 2;
}
.discount-per-m2 {
  border-radius: 2rem;
  background-color: var(--gray-100);
  width: 100%;
  border: 0.25rem solid var(--gray-100);
  padding: 0.75rem 1.25rem;
  & ::v-deep input {
    font-weight: 700;
    color: var(--gray-600);
  }
}
.discount-per-m2:focus-within {
  border: 0.25rem solid var(--gray-200);
  background-color: var(--gray-100);
  box-sizing: border-box;
}
