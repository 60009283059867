
@import "@/assets/scss/utils/pagination.scss";
@import "@/assets/scss/utils/b-table-redesign.scss";

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-size: 20px;
  background-position: right calc(2rem / 6) center !important;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-size: 20px;
  background-position: right calc(2rem / 6) center !important;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

.search__content {
  margin-top: 0;
}

.apartment-list-filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70vh;
}

.apartments__lock {
  position: absolute;
  left: 0;
  top: 25%;
  transform: translateY(-30%);
}
