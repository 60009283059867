::v-deep .custom-tab
  margin-top: 3rem

  .bottom__line
    display: flex
    height: 8px
    width: 100%
    position: absolute
    top: -8px
    left: 0
    border-radius: 1rem 1rem 0 0
    background-color: var(--gray-100)

  .card-header
    padding: 0
    background-color: transparent
    margin: 0
    border-bottom: none

  .tab-content
    position: relative
  //padding-top: 12px

  .card-body
    padding: 0

  .card-header-tabs
    margin: 0

  .nav-tabs
    display: flex
    flex-wrap: nowrap
    column-gap: .5rem
    position: relative

    &:after
      //content: ''
      height: 8px
      width: 100%
      position: absolute
      bottom: 0
      left: 0
      border-radius: 1rem 1rem 0 0
      background-color: var(--gray-100)

    .nav-item
      z-index: 2

    .nav-link
      display: flex
      justify-content: center
      align-items: center
      border: none
      font-weight: 600
      font-size: 16px
      line-height: 22px
      padding: 0 .5rem 1.5rem
      color: var(--gray-600)
      height: 100%

      &.active
        position: relative
        color: var(--violet-600)


        &:after
          content: ''
          height: 8px
          width: 100%
          position: absolute
          bottom: 0
          left: 0
          border-radius: 1rem 1rem 0 0
          background-color: var(--violet-600)
          z-index: 3

