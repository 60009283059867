
.input-label {
  font-family: CraftworkSans, serif;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray-400);
  width: 100%;
  display: flex;
  padding-left: 1rem;
  margin-bottom: 6px;

  span {
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.base-input {
  width: 100%;
  height: 3.5rem;
  padding: 13px 20px 13px 1.25rem;
  border: 0.25rem solid transparent;
  border-radius: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  font-family: Inter, serif;

  &.label {
    padding: 8px 20px 8px 1.25rem;
  }

  &:focus-within {
    border: 0.25rem solid var(--gray-200);
    background-color: var(--gray-100);
  }

  input {
    font-family: Inter, sans-serif !important;
    width: 100%;
    background-color: transparent;
    height: 100%;
    outline: none;
    border: none;
    font-weight: 700;
    color: var(--gray-600);
    padding: 0 !important;

    &::placeholder {
      font-weight: 600;
      color: var(--gray-400);
      font-family: Inter, sans-serif !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
      font-family: Inter, sans-serif !important;
    }
  }

  &:hover {
    background-color: var(--gray-200);
  }

  &-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.disable-input {
  background-color: var(--gray-300) !important;

  &:focus-within,
  input,
  input:hover {
    background-color: var(--gray-300);
  }
}

.clear__icon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  transition: transform 100ms ease-in;

  &:hover {
    transform: scale(1.1);
  }
}

::placeholder {
  color: var(--gray-600);
  opacity: 1;
}

input[type="date"] {
  padding-right: 2rem;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--gray-600);
}

::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 60%;
  right: 2rem;
  transform: translateY(-60%);
}

.error {
  border: 0.25rem solid var(--red-500) !important;
}

@media (max-width: 996px) {
  .search__content-input {
    width: 60%;
  }
}
