
.content__input {
  display: flex;
}

.select__type__promo {
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.select_wrapper {
  padding: 0.1rem;
  border-right: 1px solid rgb(206, 212, 218);
}

.delete__button {
  outline: none;
  border: none;
  background-color: #eb5757;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  height: 40px;
  margin-top: 32px;
  cursor: pointer;
}

.error__provider {
  color: red;
  font-size: 14px;
}

.floor__selection {
  width: 5rem;
  margin-right: 1rem;
}

.plan__group {
  width: auto;
  margin-left: 1rem;
  margin-right: 1rem;

  .input__group {
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
  }

  &-input {
    max-width: 16rem;
    border: none;
    padding-left: 0.5rem;
    background-color: transparent;
  }
}

.apartment__selection {
  width: 32rem;
}

.apartment__type-multiselect {
  width: 15rem;
}

.price__content {
  width: 20.625rem;
}

@media (max-width: 1280px) {
  .content__input {
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.5rem;
    padding: 1rem;

    & > div {
      margin-bottom: 1rem;
    }
  }

  .plan__group {
    margin-left: 0;
  }

  .floor__selection {
    width: 10rem;
  }
}
