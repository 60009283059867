
.apartment-details-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.apd-overview-titles {
  display: flex;
  column-gap: 0.5rem;

  .apd-section-name,
  .apd-section-slash {
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
  }
}

.apd-information {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  row-gap: 1.5rem;
}
