
.test {
  width: 100%;
  grid-column: 2/3;
  grid-row: 2;
  border: 2px solid var(--gray-100);
  padding: 10px;
  border-radius: 10px;
  align-self: start;
  position: relative;
  overflow: hidden;
}

.passport-title {
  color: var(--gray-400);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.pdf-placeholder {
  font-size: 40px;
  font-weight: 600;
  color: var(--gray-400);
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: unset;
  background-color: rgba(0, 0, 0, 0.2);
}
.images {
  display: grid;
  margin-bottom: 20px;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  & .image {
    position: relative;
    border: 1px solid var(--gray-100);
    border-radius: 4px;
    display: flex;
    align-content: center;
    justify-content: center;
    & img {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid var(--gray-100);
    }
  }

  & .controls {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    gap: 12px;
  }
}
.no-image {
  padding: 20px;
  text-align: center;
  color: var(--gray-600);
  font-size: 24px;
  font-weight: 600;
}
