.k-form-option {
  position: relative;
  list-style-type: none;
  margin: 8px;
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: var(--gray-100);
    color: var(--gray-600);
  }

  &-active,
  &-active:hover {
    background-color: var(--violet-100);
    color: var(--violet-600);
  }

  .option-check-icon {
    //display: flex;
    //position: absolute;
    width: 20px;
    right: 18px;
    //top: 50%;
    //transform: translateY(-50%);
  }
}
