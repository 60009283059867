
.navigation__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 2rem;

  .go__back {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: var(--gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .breadcrumb__content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    font-weight: 600;
    font-size: 14px;
    color: #9ca3af;
  }

  .head {
    font-size: 24px;
    line-height: 28px;
    color: #4b5563;

    .contract__number {
      color: var(--violet-600);
    }
  }
}

.status-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.price-button {
  display: flex;
  padding-right: 0;
  padding-top: 3px;
  margin: 0;
  background-color: transparent !important;
}

.object-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

::v-deep .status-sort {
  display: flex;
  column-gap: 30px;
  margin-bottom: 21px;
  font-family: Inter, sans-serif;

  .custom-control-input:checked ~ .custom-control-label::after {
    top: 0.3rem;
  }

  .badge {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2rem;
    padding: 0.25px 0.5rem;
    margin-left: 0.5rem;
  }

  .teal {
    color: var(--teal-600);

    .badge {
      color: var(--teal-600);
      background-color: var(--teal-100);
    }

    .custom-control-label {
      &::before {
        border: 2px solid var(--teal-600);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--teal-600);
    }
  }

  .yellow {
    color: var(--yellow-600);

    .badge {
      color: var(--yellow-600);
      background-color: var(--yellow-100);
    }

    .custom-control-label {
      &::before {
        border: 2px solid var(--yellow-600);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--yellow-600);
    }
  }

  .blue {
    color: var(--light-blue-600);

    .badge {
      color: var(--light-blue-600);
      background-color: var(--light-blue-100);
    }

    .custom-control-label {
      &::before {
        border: 2px solid var(--light-blue-600);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--light-blue-600);
    }
  }

  .gray {
    color: var(--red-600);

    .badge {
      color: var(--red-600);
      background-color: var(--red-100);
    }

    .custom-control-label {
      &::before {
        border: 2px solid var(--red-600);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--red-600);
    }
  }

  .disabled {
    color: var(--gray-400);

    .badge {
      color: var(--gray-400);
      background-color: var(--gray-100);
    }

    .custom-control-label {
      &::before {
        border: 2px solid var(--gray-400);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--gray-400);
    }
  }
}
