
.disabled {
  position: relative;
}

.disabled::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 6px;
  width: 100%;
  height: 100%;
  border-top: 3px solid white; /* Adjust thickness and color as needed */
  transform: rotate(45deg);
  transform-origin: top left;
}
