
.app-tab-title {
  display: flex;
  justify-content: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;

  &-number {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    line-height: 20px;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: var(--gray-400);
    background-color: var(--gray-100);
  }

  &-content {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    color: var(--gray-400);
  }

  &-right-icon {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}
