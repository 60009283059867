
.filter-button {
  height: 100%;
  width: auto;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  background-color: var(--gray-100);
  margin-left: 0.5rem;
  cursor: pointer;

  .filter-icon {
    margin-right: 1.25rem;
  }
}
