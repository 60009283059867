
.b__dropdown__group {
  ::v-deep .btn.dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: 0.5px 1px 0 rgba(5, 8, 9, 0.1), 0 5px 9px 1px rgba(5, 8, 9, 0.1) !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50% !important;
  }
}
