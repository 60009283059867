
.ch-payment-result {
  border-radius: 2rem;
  border: 2px solid var(--gray-200);
  padding: 1.5rem;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  height: fit-content;

  .ch-payment-information {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    justify-items: stretch;
    row-gap: 1.5rem;
  }

  .ch-border-dashed {
    border-top: 2px dashed var(--gray-200);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .ch-total-price {
    display: grid;
    grid-template-columns: auto auto;
    align-items: end;
  }

  .ch-price-cell {
    justify-self: end;
  }

  .ch-price-total-cell {
    align-self: end;
  }

  .ch-price-total {
    font-size: 24px;
    line-height: 30px;
  }
}
