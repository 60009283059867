
.avatar-wrapper {
  background-color: darkorchid;
  border-radius: 50%;
  box-shadow: 0.5px 0.5px -1px 0 rgba(0, 0, 0, 0.75);
}

.branches__icon {
  fill: var(--dark);
}

.release-info {
  font-family: "CraftworkSans", serif;

  &-header {
    display: flex;
    align-items: center;
    gap: 65px;
    justify-content: space-between;

    p {
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
      color: #4b5563;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-block {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-date {
        font-family: "Inter", serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #9ca3af;
      }

      &-release {
        font-family: "CraftworkSans", serif;
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        color: #4b5563;
      }

      &-tag {
        text-transform: capitalize;
        width: max-content;
        padding: 13px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-family: "CraftworkSans", serif;
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;

        &-new {
          color: #16a34a;
          background: #dcfce7;
        }

        &-edited {
          color: #0284c7;
          background: #e0f2fe;
        }
      }
    }
  }

  &-footer {
    display: flex;
    gap: 18px;

    p {
      font-family: "Inter", serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #9ca3af;
    }
  }
}
