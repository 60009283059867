
.options {
  position: absolute;
  top: 100%;
  width: 100%;
}
.card1 {
  background-color: var(--gray-100);
  width: 360px;
  border-radius: 32px;
  padding: 20px;
  overflow: visible;
  //font-weight: 600;

  &-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--gray-500);
  }
  &-label {
    font-weight: 600;
    color: #9ca3af;
  }
  &-value {
    color: #4b5563;
  }
}
.fw700 {
  font-weight: 700;
}
