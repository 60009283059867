
.loading__content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;

  &-main {
    animation: 1.5s spin infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
