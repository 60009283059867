.navbar {
  background-color: var(--background-color-navbar);
  //box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  position: relative;
  z-index: 12;
  right: 0;
  top: 0;
  width: calc(100% - 304px);
  left: 304px;

  &-top {
    background-color: var(--gray-100);
  }
}


.fixed-menu {
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  background-color: var(--gray-100);
  height: 100%;
  //padding: 3rem 1.5rem 1.5rem;
  padding: 3rem 1.5rem 2rem;
  width: 304px;
  z-index: 99;
  position: fixed;
  top: 0;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  //box-shadow: 0 0 15px 0 var(--white);

  .menu-content {
    //height: 100%;
    //overflow-y: auto;
  }
}


.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  //min-height: 100vh;
  ul {
    margin-bottom: 0;
  }

  a .material-symbols-rounded {
    margin-right: 18px;
  }

}

.menu-toggle__icon {
  margin-right: 18px;
  transition: .3s all linear;
}

.toggle-open {
  .menu-toggle__icon {
    transform: rotate(180deg);
  }
}

.toggle-close {
  & a {
    //background-color: var(--gray-300) !important;
  }
}

.menu-version {
  font-family: CraftworkSans;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: var(--gray-400);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;

}

.menu-content {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
  position: relative;
  padding: 0;
  width: 100%;

  .menu__item {
    &:hover {
      a {
        background-color: var(--gray-200);
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px !important;
      height: 20px;
    }
  }

  .menu__item a i {
    font-size: 20px;
    width: 24px;
    color: var(--dark);
    margin-right: 18px;
    flex-shrink: 0;
  }

  .menu__item a.active-link i {
    color: var(--white);
  }

  .menu__item a.inactive-link.active {
    //transition: transform 0.15s ease, -webkit-transform 0.15s ease;
    color: var(--dark) !important;
  }

  .menu__item a.active-link.active {
    color: var(--white) !important;
  }

  .menu__item a {
    margin: 0;
    padding: 16px 16px 16px 18px;
    color: #625f6e;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    display: flex;
    align-items: center;
    transition: unset;
    height: 56px;
    border-radius: 1rem;

  }

  .menu__item a:hover * {
    //transform: translateX(5px);
  }

  .menu__item a.active-link.router-link-exact-active,
  .menu__item a.active-link.active {
    background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%);
    color: #fff !important;
    //box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  }

  //li a.router-link-exact-active *,
  //li a.active * {
  //  color: #fff;
  //}

  .menu__img {
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 559px) {
    .menu__img {
      width: 30px;
      height: 30px;
    }
  }

  .menu__item {
    position: relative;
    white-space: nowrap;
    list-style: none;

    @include mq("tablet", max) {
      font-size: 1.5rem;
    }

    &-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .menu__name {
    font-family: "CraftworkSans", serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    //margin-top: 0.5rem;
    //color: var(--dark);
    color: var(--gray-600);
  }

  .active-link .menu__name {
    color: var(--white);
  }

  .menu__count {
    //position: absolute;
    //top: 8px;
    //right: 30px;
    //display: flex;
    //justify-content: flex-end;
    padding: 0.3rem 0.5rem;
    text-align: center;
    font-weight: 500;
    /* background-color: rgba(40,199,111,.12); */
    background-color: var(--violet-600);
    color: #ffffff;
    border-radius: 10rem;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
  }

  .menu__item a.router-link-exact-active .menu__count {
    //background-color: rgba(173, 255, 209, 0.25);
  }

}

.brand-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72px;
  //margin-bottom: 50px;

  img {
    object-position: top;
    width: 100%;
    max-width: 135px;
  }
}

//.navbar-header {
//  position: relative;
//  z-index: 22;
//  display: flex;
//  padding: 0.35rem 1rem 0.3rem 1rem;
//
//  ul {
//    width: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    padding: 0;
//    margin: 0;
//    list-style-type: none;
//
//    li {
//      list-style-type: none;
//    }
//  }
//
//  img {
//    max-width: 100px;
//  }
//
//  .brand-text {
//    display: inline-block;
//    font-size: 1.25rem;
//    line-height: inherit;
//    white-space: nowrap;
//    margin-bottom: 0;
//    color: var(--primary);
//  }
//
//  .nav-toggle {
//    padding: 0;
//    margin: 1rem 0;
//  }
//
//  .navbar-toggler {
//    padding: 0;
//    color: white;
//  }
//
//  .navbar-collapse {
//    margin-left: 3rem;
//  }
//
//  @media only screen and (max-width: 1247px) {
//    .navbar-collapse {
//      margin-left: 0;
//      margin-top: 10px;
//    }
//  }
//
//  .nav-item {
//    padding: 0 15px;
//    font-size: 14px;
//    font-weight: 500;
//    color: white;
//    text-transform: uppercase;
//    line-height: 1.143;
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    -webkit-box-align: center;
//    -ms-flex-align: center;
//    align-items: center;
//    position: relative;
//  }
//
//  .nav-item::after {
//    position: absolute;
//    content: "";
//    top: 50%;
//    right: 0;
//    background-color: white;
//    opacity: 0.149;
//    width: 1px;
//    height: 25px;
//    -webkit-transform: translateY(-50%);
//    transform: translateY(-50%);
//  }
//
//  @media only screen and (max-width: 1247px) {
//    .nav-item::after {
//      display: none;
//    }
//  }
//
//  @media only screen and (max-width: 1247px) {
//    .nav-item {
//      margin: 10px 0;
//      padding-left: 0;
//    }
//  }
//
//  .nav-toggle svg {
//    stroke: var(--primary);
//  }
//
//  .nav-toggle:hover svg {
//    stroke: var(--primary);
//  }
//}

.header-navbar-shadow {
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  color-stop(44%, hsla(0, 0%, 97.3%, 0.95)),
                  color-stop(73%, hsla(0, 0%, 97.3%, 0.46)),
                  to(hsla(0, 0%, 100%, 0))
  );
  background: linear-gradient(
                  180deg,
                  hsla(0, 0%, 97.3%, 0.95) 44%,
                  hsla(0, 0%, 97.3%, 0.46) 73%,
                  hsla(0, 0%, 100%, 0)
  );
  left: 0;
  padding-top: 2.2rem;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 11;
}

.shadow-bottom {
  margin-top: -0.7rem;
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  color-stop(41%, #fff),
                  color-stop(95%, hsla(0, 0%, 100%, 0.11)),
                  to(hsla(0, 0%, 100%, 0))
  );
  background: linear-gradient(
                  #fff 41%,
                  hsla(0, 0%, 100%, 0.11) 95%,
                  hsla(0, 0%, 100%, 0)
  );
  position: absolute;
  z-index: 2;
  height: 0;
  width: 100%;
  pointer-events: none;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

@include mq("tablet", max) {
  .sidenav-overlay {
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    height: 120vh;
    z-index: 1029;
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-color: rgba(34, 41, 47, 0.5);

    &.show {
      opacity: 1;
      visibility: visible;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
}

@include mq("desktop", min) {
  .menu-collapsed {
    .fixed-menu {
      width: 104px;
    }

    & + main {
      width: calc(100% - 104px);
      margin-left: 104px;
    }

    & .navbar {
      left: 104px;

      &-header {
        padding: 7px;
        justify-content: center;

        .brand-text {
          display: none;
        }

        .nav-toggle {
          display: none;
        }

        img {
          max-width: 55px;
        }

        .brand-logo {
          margin-left: 8px;
        }
      }
    }

    .menu__count {
      // opacity: 0;
      // visibility: hidden;
      display: none;
    }

    .fixed-top {
      width: calc(100% - 104px);
    }
  }
  .menu-collapsed {
    .fixed-menu:hover {
      border-radius: 0 4rem 4rem 0;
      box-shadow: 16px 0px 64px rgba(0, 0, 0, 0.16), 16px 0px 24px rgba(0, 0, 0, 0.06);
    }
  }

  .menu-expanded:hover {

    & .navbar {
      &-header {
        padding: 0.35rem 1rem 0.3rem 1rem;
        justify-content: space-between;

        .brand-text {
          display: block;
        }

        .nav-toggle {
          display: block;
        }

        img {
          max-width: 100px;
        }
      }
    }

    .menu__count {
      // opacity: 1;
      // visibility: visible;
      display: block;
    }

    .fixed-menu {
      width: 304px;
    }
  }
}

@include mq("desktop", max) {
  .navbar {
    width: 100%;
    left: 0;
  }
  .fixed-menu {
    left: -100%;
  }
  .menu-collapsed {
    .fixed-menu {
      left: 0;
    }
  }
}

.dark-theme {
  .navbar-header img, .brand-text {
    filter: contrast(50%);
  }
}



//.menu-expanded{
//  .brand-logo{
//    margin-bottom: 0px;
//  }
//}