
.secondary__cards {
  &__wrapper {
    //display: flex;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem 1rem;
  }

  &__spinner{
    width: 2rem;
    height: 2rem;
    //border: 20px solid var(--violet-600);
  }
}
