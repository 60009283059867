
.phone-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.clients-data-wrapper,
.clients-contact-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;

  .ch-client-email,
  .ch-legal-address {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

.clients-data-wrapper {
  margin-bottom: 3rem;
}

.extra-phones-wrapper {
  grid-column: 1/2;
  display: flex;
  gap: 0.5rem;
}

.remove-extra-phone-btn {
  padding: 1rem;
}

.add-extra-phone-btn {
  grid-column: 1/2;
  padding: 18px 24px 18px 18px;
  width: fit-content;
}
