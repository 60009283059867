
.plan-container {
  width: 100%;
  aspect-ratio: 1/0.8;
}
.pdf-plan-image {
  display: block;
  width: 100%;
  height: 100% !important;
}
.col-1_6 {
  width: 15.8%;
}
.col-1_6:not(:first-child),
.col-1_7:not(:first-child) {
  margin-left: 1%;
}
.col-1_7 {
  width: 13.4%;
}
.address {
  color: #828183;
  font-family: Ruberoid;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}
.qr svg {
  display: inline-block;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disclaimer {
  color: #828183;
  font-family: Ruberoid;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 16px */
}
.last_payment {
  color: #222;
  font-family: Ruberoid;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detail_row {
  color: #222;
  font-family: Ruberoid;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}
.detail_title {
  color: #999;
  font-family: Ruberoid;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}
.borderb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.table_title {
  color: #999;
  font-family: Ruberoid;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 0;
  word-wrap: normal;
}
.table_value {
  font-family: Ruberoid;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding-left: 0;
  padding-right: 0;
  line-height: normal;
}
.table_black {
  color: #222;
}
.table_green {
  color: #09b849;
}
.table_purple {
  color: #6e28d7;
}

.header {
  font-family: Ruberoid;
  max-width: 450px;
  &_logo {
    width: 250px;
    height: auto;
  }
  .object {
    font-family: Ruberoid;
    margin-top: 15px;
    color: #6e28d7;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .apartment_data {
    font-family: Ruberoid;
    color: #6e28d7;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 36px */
  }
  .detail {
    font-family: Ruberoid;
    margin-top: 4px;
    background-color: #fff;
    color: #828183;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
}
.feature {
  font-family: Ruberoid;
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0;
  .title {
    font-family: Ruberoid;
    color: #929193;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .value {
    font-family: Ruberoid;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  img {
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }
}

.section_title {
  color: #6e28d7;
  font-family: Ruberoid;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}
.pdf-page {
  position: relative;
  padding: 50px;
  padding-top: 0;
  height: 1120px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  & > .row {
    padding-left: 15px;
    padding-right: 15px;
  }
  & > .row.fix {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  & > div > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
