
.go__back
  width: 56px
  height: 56px
  border-radius: 100%
  background-color: var(--gray-100)
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

.content__form__select
  display: flex
  align-items: center
  justify-content: space-between
  background-color: var(--gray-100)
  border-radius: 2rem
  width: 50%
  border: none
  color: var(--gray-600)
  position: relative

  .form__select
    background-color: transparent
    border: none
    color: var(--gray-600)
    margin: 0 1rem
    width: 100%
