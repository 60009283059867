/* Define styles for the default root window element */
:root {
  --background-color: #fff;
  --background-color-nav: #fff;
  --background-color-dropdown: #f9fbff;
  --background-color-main: transparent;
  --background-color-navbar: #5851d8;
  --white: #fff;
  --plus-icon: #5851d8;
  --primary: #5851d8;
  --success: #45a85c;
  --info: #0c5460;
  --warning: #ff9f43;
  --danger: #dc3545;
  --ligth: #bebebe;
  --table-ligth: #000;
  --dark: #535353;
  --secondary: #82868b;
  --link: #222222;
  --placeholder: #969696;
  --dropdown-icon: #55547a;
  --border-color: #22222233;
  --object-color: #000;
  --element-size: 1rem;
  --btn-green-color: #000;
  --content-border-color: 1px solid #BDBDBD;

  /* violet */
  --violet-50: #F5F3FF;
  --violet-100: #EDE9FE;
  --violet-200: #DDD6FE;
  --violet-300: #C4B5FD;
  --violet-400: #A78BFA;
  --violet-500: #8B5CF6;
  --violet-600: #7C3AED;
  --violet-700: #6D28D9;
  --violet-800: #5B21B6;
  --violet-900: #4C1D95;

  /* gray */
  --gray-50: #F9FAFB;
  --gray-100: #F3F4F6;
  --gray-200: #E5E7EB;
  --gray-300: #D1D5DB;
  --gray-400: #9CA3AF;
  --gray-500: #6B7280;
  --gray-600: #4B5563;
  --gray-700: #374151;
  --gray-800: #1F2937;
  --gray-900: #111827;

  /* red */
  --red-50: #FEF2F2;
  --red-100: #FEE2E2;
  --red-200: #FECACA;
  --red-300: #FCA5A5;
  --red-400: #F87171;
  --red-500: #EF4444;
  --red-600: #DC2626;
  --red-700: #B91C1C;
  --red-800: #991B1B;
  --red-900: #7F1D1D;

  /* orange */
  --orange-50: #FFF7ED;
  --orange-100: #FFEDD5;
  --orange-200: #FED7AA;
  --orange-300: #FDBA74;
  --orange-400: #FB923C;
  --orange-500: #F97316;
  --orange-600: #EA580C;
  --orange-700: #C2410C;
  --orange-800: #9A3412;
  --orange-900: #7C2D12;

  /* yellow */
  --yellow-50: #FEFCE8;
  --yellow-100: #FEF9C3;
  --yellow-200: #FEF08A;
  --yellow-300: #FDE047;
  --yellow-400: #FACC15;
  --yellow-500: #EAB308;
  --yellow-600: #CA8A04;
  --yellow-700: #A16207;
  --yellow-800: #854D0E;
  --yellow-900: #713F12;

  /* green */
  --green-50: #F0FDF4;
  --green-100: #DCFCE7;
  --green-200: #BBF7D0;
  --green-300: #86EFAC;
  --green-400: #4ADE80;
  --green-500: #22C55E;
  --green-600: #16A34A;
  --green-700: #15803D;
  --green-800: #166534;
  --green-900: #14532D;

  /* teal */
  --teal-50: #F0FDFA;
  --teal-100: #CCFBF1;
  --teal-200: #99F6E4;
  --teal-300: #5EEAD4;
  --teal-400: #2DD4BF;
  --teal-500: #14B8A6;
  --teal-600: #0D9488;
  --teal-700: #0F766E;
  --teal-800: #115E59;
  --teal-900: #134E4A;

  /* light-blue */
  --light-blue-50: #F0F9FF;
  --light-blue-100: #E0F2FE;
  --light-blue-200: #BAE6FD;
  --light-blue-300: #7DD3FC;
  --light-blue-400: #38BDF8;
  --light-blue-500: #0EA5E9;
  --light-blue-600: #0284C7;
  --light-blue-700: #0369A1;
  --light-blue-800: #075985;
  --light-blue-900: #0C4A6E;

  /* blue */
  --blue-50: #EFF6FF;
  --blue-100: #DBEAFE;
  --blue-200: #BFDBFE;
  --blue-300: #93C5FD;
  --blue-400: #60A5FA;
  --blue-500: #3B82F6;
  --blue-600: #2563EB;
  --blue-700: #1D4ED8;
  --blue-800: #1E40AF;
  --blue-900: #1E3A8A;

  /* pink */
  --pink-50: #FDF2F8;
  --pink-100: #FCE7F3;
  --pink-200: #FBCFE8;
  --pink-300: #F9A8D4;
  --pink-400: #F472B6;
  --pink-500: #EC4899;
  --pink-600: #DB2777;
  --pink-700: #BE185D;
  --pink-800: #9D174D;
  --pink-900: #831843;

}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color: #10163a;
  --background-color-nav: #283046;
  --background-color-navbar: #283046;
  --background-color-dropdown: #10163a;
  --background-color-main: transparent;
  --object-color: #fff;
  --white: #3b4253;
  --plus-icon: #fff;
  --primary: #7367f0;
  --success: #45a85c;
  --info: #0c5460;
  --warning: #ff9f43;
  --danger: #dc3545;
  --ligth: #3b4253;
  --dark: #dcdcdc;
  --secondary: #82868b;
  --link: #dcdcdc;
  --placeholder: #82868b;
  --dropdown-icon: #dcdcdc;
  --border-color: #dcdcdc;
  --element-size: 1rem;
  --btn-green-color: #fff;
  --content-border-color: 1px solid #ced4da;

  /* violet */
  --violet-50: #F5F3FF;
  --violet-100: #EDE9FE;
  --violet-200: #DDD6FE;
  --violet-300: #C4B5FD;
  --violet-400: #A78BFA;
  --violet-500: #8B5CF6;
  --violet-600: #7C3AED;
  --violet-700: #6D28D9;
  --violet-800: #5B21B6;
  --violet-900: #4C1D95;

  /* gray */
  --gray-50: #F9FAFB;
  --gray-100: #F3F4F6;
  --gray-200: #E5E7EB;
  --gray-300: #D1D5DB;
  --gray-400: #9CA3AF;
  --gray-500: #6B7280;
  --gray-600: #4B5563;
  --gray-700: #374151;
  --gray-800: #1F2937;
  --gray-900: #111827;

  /* red */
  --red-50: #FEF2F2;
  --red-100: #FEE2E2;
  --red-200: #FECACA;
  --red-300: #FCA5A5;
  --red-400: #F87171;
  --red-500: #EF4444;
  --red-600: #DC2626;
  --red-700: #B91C1C;
  --red-800: #991B1B;
  --red-900: #7F1D1D;

  /* orange */
  --orange-50: #FFF7ED;
  --orange-100: #FFEDD5;
  --orange-200: #FED7AA;
  --orange-300: #FDBA74;
  --orange-400: #FB923C;
  --orange-500: #F97316;
  --orange-600: #EA580C;
  --orange-700: #C2410C;
  --orange-800: #9A3412;
  --orange-900: #7C2D12;

  /* yellow */
  --yellow-50: #FEFCE8;
  --yellow-100: #FEF9C3;
  --yellow-200: #FEF08A;
  --yellow-300: #FDE047;
  --yellow-400: #FACC15;
  --yellow-500: #EAB308;
  --yellow-600: #CA8A04;
  --yellow-700: #A16207;
  --yellow-800: #854D0E;
  --yellow-900: #713F12;

  /* green */
  --green-50: #F0FDF4;
  --green-100: #DCFCE7;
  --green-200: #BBF7D0;
  --green-300: #86EFAC;
  --green-400: #4ADE80;
  --green-500: #22C55E;
  --green-600: #16A34A;
  --green-700: #15803D;
  --green-800: #166534;
  --green-900: #14532D;

  /* teal */
  --teal-50: #F0FDFA;
  --teal-100: #CCFBF1;
  --teal-200: #99F6E4;
  --teal-300: #5EEAD4;
  --teal-400: #2DD4BF;
  --teal-500: #14B8A6;
  --teal-600: #0D9488;
  --teal-700: #0F766E;
  --teal-800: #115E59;
  --teal-900: #134E4A;

  /* light-blue */
  --light-blue-50: #F0F9FF;
  --light-blue-100: #E0F2FE;
  --light-blue-200: #BAE6FD;
  --light-blue-300: #7DD3FC;
  --light-blue-400: #38BDF8;
  --light-blue-500: #0EA5E9;
  --light-blue-600: #0284C7;
  --light-blue-700: #0369A1;
  --light-blue-800: #075985;
  --light-blue-900: #0C4A6E;

  /* blue */
  --blue-50: #EFF6FF;
  --blue-100: #DBEAFE;
  --blue-200: #BFDBFE;
  --blue-300: #93C5FD;
  --blue-400: #60A5FA;
  --blue-500: #3B82F6;
  --blue-600: #2563EB;
  --blue-700: #1D4ED8;
  --blue-800: #1E40AF;
  --blue-900: #1E3A8A;

  /* pink */
  --pink-50: #FDF2F8;
  --pink-100: #FCE7F3;
  --pink-200: #FBCFE8;
  --pink-300: #F9A8D4;
  --pink-400: #F472B6;
  --pink-500: #EC4899;
  --pink-600: #DB2777;
  --pink-700: #BE185D;
  --pink-800: #9D174D;
  --pink-900: #831843;

}

html {
  scroll-behavior: smooth !important;
}

* {
  outline: none !important;
}

*::-moz-selection {
  background-color: var(--primary);
  color: white;
}

*::selection {
  background-color: var(--primary);
  color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

body {
  width: 100%;
  height: 100%;
  //background-image: url("../img/bg_img.png");
  background-attachment: fixed;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: Inter, sans-serif;
  background-color: var(--gray-100) !important;
}

.main-content {
  margin-left: 304px;
  position: relative;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  //min-height: calc(100vh - 66px);
  min-height: 100vh;
  padding: 3rem 3rem 0 3rem;
  background-color: var(--white);
  border-radius: 0 0 0 56px;

  @include mq("desktop", max) {
    margin: 0;
    padding: 2rem;
  }
}

.main-content > div.d-flex.justify-content-between > div {
  margin-top: 15px !important;
  padding-left: 15px;
}

.main-content > div.d-flex.justify-content-between > div .title__big {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.font-inter {
  font-family: Inter, sans-serif !important;
}

.font-craftworksans {
  font-family: CraftworkSans, serif !important;
}


// Base grayscale colors definitions
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


// Base colors definitions
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Theme color default definitions
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// This table defines the theme colors (variant names)
$theme-colors: () !default;
$theme-colors: map-merge(
                (
                        'primary': $primary,
                        'secondary': $secondary,
                        'success': $success,
                        'info': $info,
                        'warning': $warning,
                        'danger': $danger,
                        'light': $light,
                        'dark': $dark,
                        'blue': $blue,
                ),
                $theme-colors
);

//@import '/node_modules/bootstrap/scss/bootstrap';
//@import '../node_modules/bootstrap-vue/src/index.scss';
//@import "bootstrap/dev_version_16_09_23/css/bootstrap.css";
//@import "bootstrap-vue/dev_version_16_09_23/bootstrap-vue.css";
