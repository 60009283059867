
.collapsed {
  .base-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px !important;

    .person {
      display: none !important;
    }
  }
}

::v-deep {
  .b-avatar .b-avatar-text {
    line-height: initial;
  }
}

.lang-switcher {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 1rem;
}

.base-avatar {
  display: flex;
  gap: 8px;
  width: max-content;
  align-items: center;
  border-radius: 32px;
  margin-right: 0;
  padding: 7px 16px 7px 8px;
  height: 56px;
  min-width: 56px;

  &:hover {
    background-color: var(--gray-200) !important;

    .person {
      .full_name {
        color: var(--violet-600);
      }
    }
  }

  p {
    margin-bottom: 0;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    border-radius: 32px;
    background-color: red;
    object-fit: contain;
    width: 40px;
    height: 40px;
  }

  .person {
    display: flex;
    font-family: Inter, sans-serif;
    align-items: flex-start;
    flex-direction: column;

    .full_name {
      color: var(--gray-600);
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }

    .role {
      color: var(--gray-400);
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

::v-deep {
  .notArrow .b-dropdown .btn:not(.dropdown-item),
  .notArrow .btn-secondary:not(.dropdown-item) {
    border-radius: 2rem !important;
  }
}

::v-deep .lang-block {
  .dropdown-item {
    padding: 0 !important;
  }
}
