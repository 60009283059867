
.agreement__details {
  display: grid;
  grid-template-columns: repeat(2, minmax(22.5rem, 1fr));
  gap: 1rem 3rem;
}

.owners {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;

  &__column {
    display: flex;
    flex-flow: column;
    row-gap: 1rem;
  }

  &__title {
    font-family: CraftworkSans, serif;
    font-size: 1.5rem;
    line-height: 28px;
    color: var(--gray-400);
    font-style: normal;
    font-weight: 900;
  }

  &__tooltip__icon {
    cursor: pointer;
    margin-left: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.download__button {
  cursor: pointer;
  width: 100%;
}

.assignee-header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-family: CraftworkSans, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: var(--gray-400);
  margin-bottom: 1rem;
  margin-top: 2rem;

  &__tooltip {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    //text-transform: uppercase;
    margin: 0;
  }
}

@media (max-width: 53.75rem) {
  .agreement__details,
  .owners {
    grid-template-columns: 1fr;
  }

  .owners {
    margin-top: 0;

    &__title {
      margin-top: 3rem;
    }
  }
}
