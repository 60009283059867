
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 1rem;
  margin-bottom: 2rem;

  .currency-info {
    position: relative;

    & .currency-wrap {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      background-color: var(--gray-100);
      padding: 10px;
      border-radius: 20px;
      box-shadow: 0px 0px 4px var(--gray-600);
      z-index: 10;
    }
    &:hover .currency-wrap {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      & .info-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    column-gap: 0.5rem;
  }

  &-title {
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    color: var(--gray-600);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
