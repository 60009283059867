
.object-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.card {
  border-radius: 1rem;
  width: 412px;
  border: none;
  cursor: pointer;
  font-family: Inter, sans-serif;

  &-body {
    border-radius: 1rem;
    padding: 28px;
    background-color: var(--gray-100);
  }

  &:hover {
    .card-body {
      background-color: var(--violet-100);
    }
  }

  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid var(--gray-200);
    padding-bottom: 16px;
    margin-bottom: 16px;

    &__content {
    }
  }

  ::v-deep .card-bottom {
    margin-top: -1rem;

    .swiper-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18rem;

      .swiper-slide {
        cursor: grab;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .swiper-image {
          //display: flex;
          //justify-content: center;
          //align-items: center;
          width: 16rem;
          height: 16rem;
          object-fit: contain;
        }
      }

      .swiper-button {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: var(--violet-100);
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next::after,
      .swiper-button-prev::after {
        content: none;
      }

      .swiper-pagination {
        margin-top: 3rem;

        &-bullets {
          bottom: 1rem;
        }

        &-bullet {
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.3rem;
          background-color: var(--gray-400);

          &-active {
            background-color: var(--violet-400);
          }
        }
      }
    }
  }

  &-title {
    color: var(--violet-600);
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &-subtitle {
    font-size: 1rem;
    line-height: 22px;
    font-weight: 600;
    color: var(--gray-500);
    margin: 0;
  }

  &-button {
    width: max-content;
    border-radius: 1rem;
    background-color: var(--white);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-400);
    margin-bottom: 12px;
    padding: 5px 10px;
  }

  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--gray-500);
    }

    .price {
      font-size: 18px;
      line-height: 24px;
      color: var(--violet-600);
    }

    &__title {
    }
  }

  &-img {
    height: 206px;

    img {
      border-radius: 0 0 1rem 1rem;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
