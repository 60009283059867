
.go__back {
  margin-right: 10px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-200);
  }
}

.map-box-show {
  width: 100%;
  height: 90%;
  min-height: 700px;
}
