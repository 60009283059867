
@import "../../assets/scss/reusable/debtors-ui";

//.calendar-layout {
//  overflow-x: scroll;
//}

.calendar-content {
  height: 54rem;
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(7, 1fr);
  font-family: CraftworkSans, serif;
  gap: 0;

  &-cell {
    overflow-y: hidden;
    justify-self: stretch;
    border: 1px solid var(--gray-100);
    //min-width: 16rem;
    min-height: 8.5rem;
    position: relative;
    overflow-x: hidden;

    .cell-top-content {
      //width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.75rem;

      .day-of-month {
        color: var(--gray-400);
      }

      .day-of-week {
        color: var(--gray-600);
      }
    }

    .cell-middle-content {
      display: block;
      background-color: var(--violet-600);
      border-radius: 0.25rem;
      margin: 0 0.25rem;
      color: white;
      padding: 0.5rem;
      font-size: 14px;
      max-height: 100px;
      text-overflow: ellipsis;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      white-space: nowrap;
      line-break: anywhere;
      overflow: hidden;
    }
  }
}

.content-no-wrap {
  overflow-x: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}
.debtor {
  display: flex;
  position: relative;
  justify-content: space-between;
  .friend {
    width: 85%;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
    height: 15px;
    width: 15px;
  }
}
