
.circular-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &.small {
    width: 32px;
    height: 32px;
  }
}
