
// .apartments {
//   gap: 20px;
// }
.navigation__content {
  display: flex;
  align-items: center;
  //margin-bottom: 52px;

  .breadcrumb__content {
    display: inline-flex;
    flex-direction: column;
    margin-left: 1rem;
    font-weight: 600;
    font-size: 14px;
    color: #9ca3af;
  }

  .head {
    font-size: 24px;
    line-height: 28px;
    color: #4b5563;

    .contract__number {
      color: var(--violet-600);
    }
  }
}
.input-left {
  background-color: var(--gray-100);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  border-right: 2px var(--gray-300) solid;
  width: 150px;
  &:focus-within,
  &:hover {
    background-color: var(--gray-200);
  }
}
.input-right {
  background-color: var(--gray-100);
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  flex-grow: 1;
  &:focus-within,
  &:hover {
    background-color: var(--gray-200);
  }
}
.discount-per-m2 {
  border-radius: 2rem;
  background-color: var(--gray-100);
  width: 100%;
  border: none;
  padding: 12px 22px;
}
.warning__border {
  border: 1px solid var(--red-600) !important;
}
.data-picker {
  border-radius: 2rem;
}

.client-label {
  font-weight: 600;
  font-size: 24px;
  color: #4b5563;
}
