

::v-deep .b-sidebar
    filter: drop-shadow(-16px 0px 64px rgba(0, 0, 0, 0.16)) drop-shadow(-16px 0px 24px rgba(0, 0, 0, 0.06))

    &.b-sidebar-right
        border-radius: 56px 0 0 56px

    & > .b-sidebar-body
        border-radius: 1rem

::v-deep .sidebar__apartment
    width: 42rem
    font-family: CraftworkSans, serif

    &-body
        //margin: 0 3.5rem
        //padding: 3.5rem 0
        padding: 3.5rem
        -ms-overflow-style: none
        scrollbar-width: none

        &::-webkit-scrollbar
            display: none

        .head
            line-height: 1.75rem

            .close__button
                width: 3.5rem
                height: 3.5rem
                cursor: pointer
                border-radius: 50%
                margin-right: 0.5rem
                background-color: var(--gray-100)
                transition: background-color 100ms ease-in-out

                &:hover
                    background-color: var(--gray-200)

            .section__title
                color: var(--gray-600)
                font-size: 1.5rem

            .apartment__status
                font-family: Inter, sans-serif
                background-color: var(--gray-100)
                border-radius: 2rem
                min-width: max-content
                padding: 0.5rem 2rem

        .vue-html2pdf
            .layout-container
                position: relative
                width: auto
                height: auto
                left: 0
                z-index: 0
                background: #FFFFFF
                display: block

.checkout__button
    padding: 1rem 3rem

.action-block
    display: flex
    flex-wrap: wrap
    margin-top: 1rem
    margin-bottom: .5rem
    gap: .5rem

.print__button,
.cancel__button,
.view__button
    outline: none
    border: none
    width: 3.5rem
    height: 3.5rem
    border-radius: 50%

.status
    &-waiting
        background-color: var(--yellow-100) !important
        color: var(--yellow-600) !important

    &-contract
        background-color: var(--blue-100) !important
        color: var(--blue-600) !important

    &-booked
        background-color: var(--yellow-100) !important
        color: var(--yellow-600) !important

    &-cancelled
        background-color: var(--pink-100) !important
        color: var(--pink-600) !important

    &-available
        background-color: var(--teal-100) !important
        color: var(--teal-600) !important

    &-sold
        background-color: var(--gray-100) !important
        color: var(--gray-600) !important

::v-deep .b-tooltip
    .tooltip-text
        color: #fff !important
        margin-bottom: 0
