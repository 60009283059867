
@import "@/assets/scss/utils/pagination";

::v-deep.custom-filter {
  margin-top: 32px;

  .d-flex {
    .d-flex {
      .custom-select {
        display: none;
      }
    }
  }
}

.logs-content {
  display: flex;
  flex-direction: column;
}

.accordion-list {
  padding-top: 1.5rem;
}

::v-deep .accordion {
  //margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .accordion-item:last-child {
    margin-bottom: 0 !important;

    &:after {
      display: none;
    }
  }

  .date {
    font-family: Craftwork Sans, sans-serif;
    font-weight: 900;
    color: #9ca3af;

    &-day {
      line-height: 22px;
      margin: 1.5rem 0 1.5rem;
      font-size: 18px;
      align-self: center;
    }

    &-time {
      font-size: 14px;
      margin-bottom: 4px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  .card-body {
    padding: 0;
    //padding: 0 1.5rem 1.5rem;

    &__title {
      margin: 1rem 0;
    }
  }

  .header-data {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-row {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .row-icons {
      align-items: center;
      justify-content: space-between;
      width: auto !important;
      padding: 0 8px;
    }
  }

  .w-100 {
    width: 100% !important;
  }

  header[aria-expanded="true"] {
    .collapse-button {
      transform: rotate(-180deg);
    }
  }

  .header-comment {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background-color: var(--white);
    border: 2px solid var(--gray-200);
    border-radius: 32px;
    //margin-top: 24px;
    transition: all 0.3s linear;
    margin-bottom: 24px;

    p {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .card {
    overflow: visible;
    position: relative;
    margin-bottom: 1.5rem;

    &-header {
      border-bottom: none;
      background-color: transparent;
      //margin: 0 !important;
    }
  }

  .collapsed {
    padding: 24px;
    border-radius: 16px !important;
    //margin-top: -16px !important;
  }

  .collapsed:hover {
    background: #f9fafb;
  }

  &-item {
    border: none;
    width: calc(100% - 56px);

    .header-status {
      position: absolute;
      content: "";
      top: 24px;
      left: -56px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;

      &.updated {
        background-color: var(--yellow-600);
      }

      &.created {
        background-color: var(--green-600);
      }

      &.warning {
        background-color: var(--red-600);
      }
    }

    &:after {
      position: absolute;
      content: "";
      top: 76px;
      left: -33px;
      width: 2px;
      height: calc(100% - 33px);
      background-color: #f3f4f6;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;
    }

    &__header {
      padding: 1.5rem;

      .collapse-button {
        transition: all linear 0.3s;
      }

      .header-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__item {
          display: flex;
          align-items: center;
          column-gap: 1rem;

          h5 {
            margin-bottom: 0;
          }
        }
      }

      .header-text {
        display: flex;
        margin-top: 2px;
        margin-bottom: 10px;

        p {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #4b5563;
        }
      }

      h5,
      p {
        margin-bottom: 0;
      }

      .avatar {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        img {
          border-radius: 50%;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .name {
        display: flex;
        column-gap: 6px;
        font-family: CraftworkSans, serif;
        font-weight: 900;
        font-size: 14px;
        color: var(--violet-600);
        margin-bottom: 4px;

        &-rank {
          color: var(--gray-600);
        }

        &-dot {
          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-400);
        }
      }
    }

    &__body {
      padding: 0 1.5rem 1.5rem;

      .body-title {
        font-family: CraftworkSans, serif;
        font-weight: 900;
        font-size: 16px;
        line-height: 18px;
        color: var(--gray-400);
        margin-bottom: 1rem;
      }

      .body-content {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .base__button .text {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--gray-600);
      }
    }
  }
}
