
.flip-clock {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 30px;
  color: var(--white);
  border-radius: 28px;
  height: 56px;
  padding: 13px 16px;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}
