
.tag-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-family: CraftworkSans, serif;
  color: var(--gray-600);
}

.tag-input__tag {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 0.5rem 0.75rem;
  margin-right: 0.5rem;
  //margin-top: 0.5rem;
  //margin-bottom: 0.5rem;
}

.tag-input__tag .tag {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-right: 0.5rem;
  min-width: max-content;
}

.tag-input__tag > span {
  cursor: pointer;
}

.tag-input__text {
  width: 100%;
  min-width: 10rem;
  border: none;
  outline: none;
  background-color: transparent;
  //padding-left: 1rem;
}

.addition__button {
  //width: 4rem;
  //height: 2rem;
  //border-radius: 0.5rem;
  //background-color: var(--gray-300);
  //border: 2px solid var(--gray-500);
  background-color: #ffffff;
  border-radius: 2rem;
  //padding: 0.5rem 2rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 150ms ease-in-out;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    transform: scale(1.1);
  }
}

.tag-input-active {
  min-width: 2rem !important;
  width: auto;
}
