.x-gap-1{
  gap: 1rem;
}

.x-gap-2{
  gap: 2rem;
}

.x-gap-3{
  gap: 3rem;
}

.x-gap-4{
  gap: 4rem;
}

.x-gap-5{
  gap: 5rem;
}



