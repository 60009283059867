
.base__button
  font-family: Inter, serif
  padding: 1rem 1.25rem
  max-height: 56px
  min-width: fit-content
  width: fit-content
  border-radius: 2rem
  color: var(--gray-600)
  font-size: 1rem
  line-height: 22px
  font-weight: 600
  border: none
  outline: none
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  background: var(--gray-100)
  transition: all .3s linear

  &.fixed
    width: 100%

  .text
    color: inherit
    font-family: Inter, serif

  .left__icon
    display: flex
    margin-right: 1rem

  .right__icon
    display: flex
    margin-left: 1rem

  &:hover
    background: var(--gray-200)
    color: var(--gray-600)

  &:disabled
    background: var(--gray-100)
    color: var(--gray-400)

//.disabled
//    background: var(--gray-500)
//    color: var(--white) !important

.transparent
  background: transparent
  color: var(--gray-600)

  &:hover
    background: var(--gray-200)

  &.disabled
    background: transparent !important
    color: var(--gray-400)

.violet
  background: var(--violet-700)
  color: var(--white) !important

  &:hover
    background: var(--violet-700)

  &.disabled
    background: var(--gray-100)
    color: var(--gray-400) !important

.red
  background: var(--red-600)
  color: var(--white) !important

.green
  background: var(--teal-600)
  color: var(--white) !important

.violet-gradient
  background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%)
  color: var(--white) !important

  &:hover
    background: linear-gradient(88.25deg, var(--violet-700) 0%, var(--violet-700) 100%)

  &.disabled
    background: var(--gray-100)
    color: var(--gray-400) !important

.loading
  user-select: none

  .text, .left__icon, .right__icon
    opacity: 0

  &-item
    position: absolute
    display: flex
    align-items: center
    z-index: 2
    justify-content: center

  &:hover
    background: var(--gray-100)

  &.transparent-button:hover
    background: transparent

  &.violet:hover
    background: var(--violet-700)

  &.violet-gradient:hover
    background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%)

.loading__content
  display: flex
  justify-content: center
  align-items: center
  min-height: 40rem

  &-main
    animation: 1.5s spin infinite linear

@keyframes spin
  to
    transform: rotate(360deg)
