
.square {
  padding: 12px;
  display: flex;
  flex-direction: column;
  //gap: 4px;
  width: 16.5rem;
  height: 92px;
  background-color: var(--gray-50);
  font-family: Inter, sans-serif;
  //border: 2px solid var(--white);
  cursor: pointer;
  outline: none;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-500);
    margin-bottom: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-body {
    display: flex;

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: var(--gray-600);
    }
  }

  &.yellow {
    background-color: var(--yellow-100);

    h5 {
      color: var(--yellow-600);
    }

    .square {
      &-header {
        p {
          color: var(--gray-600);
        }
      }

      &-footer {
        p {
          color: var(--gray-700);
        }
      }
    }

    &:hover {
      outline: var(--yellow-600) solid 2px !important;
      background-color: var(--white);
    }
  }

  &.teal {
    background-color: var(--teal-100);

    h5 {
      color: var(--teal-600);
    }

    .square {
      &-header {
        p {
          color: var(--gray-600);
        }
      }

      &-footer {
        p {
          color: var(--gray-700);
        }
      }
    }

    &:hover {
      outline: var(--teal-600) solid 2px !important;
      background-color: var(--white);
    }
  }

  &.blue {
    background-color: var(--light-blue-100);

    h5 {
      color: var(--light-blue-600);
    }

    .square {
      &-header {
        p {
          color: var(--gray-600);
        }
      }

      &-footer {
        p {
          color: var(--gray-700);
        }
      }
    }

    &:hover {
      outline: var(--blue-600) solid 2px !important;
      background-color: var(--white);

      .square {
        &-header {
          p {
            color: var(--gray-600);
          }
        }

        &-footer {
          p {
            color: var(--gray-400);
          }
        }
      }
    }
  }

  &.gray {
    box-sizing: inherit;
    background-color: var(--red-100);

    h5 {
      color: var(--red-600);
    }

    .square {
      &-header {
        p {
          color: var(--gray-700);
        }
      }

      &-footer {
        p {
          color: var(--gray-600);
        }
      }
    }

    &:hover {
      outline: var(--red-600) solid 2px !important;
      background-color: var(--white);

      .square {
        &-header {
          p {
            color: var(--gray-600);
          }
        }

        &-footer {
          p {
            color: var(--gray-400);
          }
        }
      }
    }
  }

  &.disable {
    background-color: var(--gray-50);

    h5,
    p {
      color: var(--gray-400);
    }

    .square {
      &-header {
        p {
          color: var(--gray-500);
        }
      }

      &-footer {
        p {
          color: var(--gray-400);
        }
      }
    }

    &:hover {
      background-color: var(--gray-50);
      outline: none !important;

      h5,
      p {
        color: var(--gray-400);
      }

      .square {
        &-header {
          p {
            color: var(--gray-500);
          }
        }
      }
    }
  }
}

.list-number {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  padding-top: 22px;
  background-color: var(--white);
  flex-direction: column;
}

.counter {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-400);
  padding: 3px;
  height: 94px;
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  //padding-left: 4px;
  //padding-top: 4px;
  padding: 4px;
}

.block {
  display: flex;
  height: 100%;
  //width: 100vw;
  //max-width: 100vw;
  //overflow-x: auto;
  overflow-y: visible;

  &-content {
    //border: 1px solid var(--gray-100);
    //min-height: 56px;
    height: auto;
    column-gap: 2px;
  }

  &-item {
    color: var(--teal-500);
    //width: 100%;
    height: auto;
    min-height: 56px;
    //border-color: var(--gray-100);
    //border-style: solid;
    //border-top: 0;
    //border-bottom: 0;
    background-color: var(--gray-50);
  }
}

.header {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-400);
  text-transform: capitalize;
  width: 100%;
  min-height: 20px;
  z-index: 3;
  background-color: #fff;

  span {
    display: block;
    position: sticky;
    left: 30px;
    margin-right: 30px;
    top: 0;
    width: max-content;
  }
}

.vertical {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  max-height: 100vh;
  padding-left: 18px;
  padding-right: 10px;
  background-color: #fff;

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-400);
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    -webkit-writing-mode: vertical-lr;
    background-color: var(--white);
  }
}

.inactive-apartment {
  .square {
    &.yellow,
    &.teal,
    &.blue,
    &.gray {
      background-color: var(--gray-50) !important;

      &:hover {
        background-color: var(--gray-50) !important;

        p,
        h5 {
          display: none;
        }
      }
    }

    .square-footer {
      .apartment-area,
      .apartment-square-price {
        display: none;
      }
    }
  }

  .apartment {
    &-square-price,
    &-price,
    &-status,
    &-number,
    &-promo-icon img {
      display: none !important;
    }
  }
}
