
.button-disabled {
  cursor: wait !important;
  opacity: 0.7;
}

.error__provider {
  color: red;
  font-size: 12px;
}

form {
  width: 350px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .container {
    margin-top: -100px;
  }
}

.dark-theme {
  .login-form {
    label {
      color: #dfdfdf;
    }
  }
}

.spinner {
  box-sizing: border-box;
  width: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid #283046;
  border-top-color: #fff;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
