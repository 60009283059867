
.price__section
  font-family: CraftworkSans, serif
  font-size: 1.5rem
  font-weight: 900
  margin: 1.5rem 0

  &-amount
    color: var(--gray-600) !important
    line-height: 28px

  &-square-amount
    color: var(--gray-500) !important
    font-size: 18px
    line-height: 22px

.button__view
  display: inline-block
  padding: 1rem 1.5rem
  background-color: var(--gray-100)
  border-radius: 2rem
  font-family: Inter, sans-serif

.promos
  margin-top: .5rem
  margin-bottom: 1rem

  .promo__section:last-child
    border-bottom: 3px solid var(--gray-100)

.promo__section
  font-family: Inter, sans-serif
  padding-top: 1.5rem
  padding-bottom: 1.5rem
  border-top: 3px solid var(--gray-100)
  font-weight: 600

  &-title
    color: var(--violet-600) !important
    font-weight: 600
    font-size: 18px
    line-height: 24px
    text-transform: capitalize

  &-subtitle
    color: var(--gray-400) !important
    font-weight: 600
    font-size: 14px
    line-height: 20px

  .total__sum
    font-size: 1.5rem
    line-height: 30px
    font-weight: 600

  .mortgage
    display: block
    border-top: 3px solid var(--gray-100)
    padding-top: 1rem

    &__title
      font-size: 1.5rem
      line-height: 30px

.apartment__details
  padding-top: 1rem
  padding-bottom: 1rem

  &-title
    font-family: CraftworkSans, serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    margin-bottom: 1.5rem
    color: var(--gray-400) !important

  &-row
    font-family: Inter, sans-serif
    font-weight: 600
    display: flex
    justify-content: space-between
    padding: 0.5rem 0

    .property
      color: var(--gray-400)
      text-transform: capitalize

    .value
      color: var(--gray-600)

.particular__qualities
  font-family: Inter, sans-serif
  margin-bottom: 2.5rem

  .title
    font-size: 1.25rem

  &-content
    display: flex
    flex-wrap: wrap

    .part
      margin-top: 1rem

      &:not(:last-child)
        margin-right: 1rem

      .image__container
        width: 4rem
        height: 4rem
        display: block
        background-color: var(--violet-300)
        margin-bottom: 0.75rem

      .description
        font-size: 14px
        line-height: 20px
