
.create {
  &-title {
    font-family: CraftworkSans, serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: var(--gray-400);
    margin-bottom: 2rem;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  &-input {
    width: 100%;
    max-width: 41rem;
    margin-bottom: 2rem;
  }
}

.price-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .base__button {
    width: max-content !important;
  }
}

.price-delete {
  cursor: pointer;
}

.footer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  max-width: 41rem;

  .base__button {
    width: 100%;
  }
}

.add-inputs {
  width: 70rem;
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin-bottom: 30px;
  row-gap: 1rem;

  &__row {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}

::v-deep {
  .b-dropdown .btn:not(.dropdown-item),
  .btn-secondary:not(.dropdown-item) {
    font-family: Inter, sans-serif;
    padding: 1rem 1rem 1rem 1.5rem !important;
    height: 56px;
    font-style: normal;
    line-height: 22px !important;
    border-radius: 2rem !important;
    color: var(--gray-400) !important;
    font-size: 1rem !important;
    border: none !important;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--gray-100) !important;
    margin: 0 !important;
    width: 32rem !important;

    &:hover {
      -webkit-box-shadow: 0 8px 25px -8px var(--gray-400) !important;
      box-shadow: 0 8px 25px -8px var(--gray-400) !important;
    }

    .input-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .input-label {
      font-weight: 900;
      font-size: 8px;
      line-height: 10px;
      margin: 0 2px 0 0;
      //margin-bottom: 2px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--gray-400);
    }

    .input-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--gray-600);
      margin: 0;
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .default-label {
      line-height: 22px;
      font-size: 1rem;
      color: var(--gray-400);
      margin: 0;
    }
  }

  .dropdown-toggle::after {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: 1rem;
    background: url("../../../../../assets/icons/icon-down.svg");
    transition: all 0.2s ease-in-out;
  }

  .show .dropdown-toggle::after {
    transform: rotate(-180deg);
  }

  .dropdown-menu {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 0.5rem;

    .dropdown-item {
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      border-radius: 1rem;
      padding: 12px 17px;
      min-width: 256px;

      &:hover {
        background-color: var(--gray-200);
      }
    }
  }

  .b-dropdown-text {
    min-width: 16rem;
    padding: 0.5rem !important;

    .form-group {
      margin-bottom: 0;
    }
  }

  .custom-control-label {
    //padding-left: .5rem;
    //width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 181, 253, 5%);
    box-shadow: 0 0 0 0.2rem rgba(196, 181, 253, 5%);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--violet-600);
    background-color: var(--violet-600);
  }

  .custom-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    font-weight: 600;
    border-radius: 1rem;
    color: var(--gray-600);
    font-size: 1rem;
    padding: 0.15rem 0.15rem 0.15rem 1rem;

    &:hover {
      background-color: var(--gray-200);
    }
  }

  .tariff-header {
    display: flex;
    flex-wrap: nowrap;
    max-width: 41rem;
    font-weight: 600;
    line-height: 22px;
    column-gap: 0.5rem;
    margin-bottom: 3rem;

    .nav-item {
      display: flex;
      width: 100%;
    }

    .nav-link {
      width: 100%;
      font-family: Inter, serif;
      padding: 1rem 1.25rem;
      max-height: 56px;
      border-radius: 2rem;
      color: var(--gray-600);
      font-size: 1rem;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: var(--white);

      &.active {
        color: var(--gray-600);
        background-color: var(--gray-100);
      }
    }
  }

  .create .base__modal input {
    padding: 0 !important;
  }
}

.filter__price {
  display: flex;
  align-self: stretch;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  width: 350px;
  padding-left: 1rem;
  border-left: 2px solid var(--gray-200);
}

.filter-value {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  color: var(--gray-400);
}

.filter__apartment__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //min-width: 20rem;
  height: 3.5rem;
  background-color: var(--gray-100);
  border-radius: 2rem;
  border: 0.25rem solid transparent;
  padding: 0 1.25rem;
  width: 100%;
  max-width: 33rem;
  color: var(--gray-600);
  position: relative;

  ::placeholder {
    color: var(--gray-400);
    opacity: 1;
  }

  .placeholder {
    color: var(--gray-400);
  }

  .input__date {
    margin-left: 0.5rem;
    background-color: transparent;
    border: none;
    width: auto;
  }

  .inline {
    background-color: transparent;
    border: none;
    color: var(--gray-600);
    padding: 0;

    .disabled__option {
      color: var(--gray-100) !important;
    }
  }

  .price__currency {
    text-transform: uppercase;
    width: 10rem;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .add-inputs {
    width: 50rem;
  }
  .filter__price {
    width: 100%;
  }
  .filter__apartment__price {
    .price__currency {
      width: 5rem;
    }
  }
  ::v-deep {
    .b-dropdown .btn:not(.dropdown-item),
    .btn-secondary:not(.dropdown-item) {
      width: 23rem !important;

      .input-text {
        max-width: 150px;
      }
    }
  }
}
