
.title {
  font-family: CraftworkSans, serif;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 14px;
  color: var(--violet-600);
  display: block;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.price {
  font-size: 1.5rem;
  line-height: 28px;
  //margin-bottom: 0.5rem;
  display: block;
  color: var(--gray-600);
}

.bottom__info {
  display: block;
  color: var(--gray-400);
  margin-top: 4px;
}

.chart__list {
  display: block;
  margin-top: 1rem;

  & > span {
    height: 22px;
  }

  .progress {
    width: 75%;
    height: 6px;
    border-radius: 1rem;
    background-color: var(--gray-200);
  }

  ::v-deep .progress-bar {
    border-radius: 1rem;
    background: linear-gradient(88.25deg, #7c3aed 0%, #818cf8 100%) !important;
  }

  .percent__value {
    display: block;
    margin-left: 8px;
    color: var(--gray-600);
    font-size: 1rem;
    line-height: 22px;
  }
}

.first__chart {
  .progress {
    width: 100%;
    height: 6px;
    border-radius: 1rem;
    background-color: var(--gray-200);
  }
}

@media screen and (max-width: 1300px) {
  .title {
    font-size: 1rem;
  }

  .price {
    font-size: 1.25rem;
  }

  .bottom__info {
    font-size: 0.8rem;
  }
}
