::v-deep .app-tabs-content {
  padding-top: 2rem;

  &-header {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: nowrap;
    //margin-bottom: 2rem;
    //border-top: 6px solid var(--gray-100);
    //border-bottom: 6px solid var(--gray-100);
    padding: 20px 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 6px;
      width: calc(100% + 6rem);
      left: -3rem;
      background-color: var(--gray-100);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 6px;
      width: calc(100% + 6rem);
      right: -3rem;
      background-color: var(--gray-100);
    }

    .nav-item {
      min-width: max-content;

      .nav-link {
        padding: 8px 6px;
      }

      .active {
        background-color: transparent;

        .app-tab-title-number {
          background-color: var(--violet-100);
          color: var(--violet-600);
        }

        p {
          color: var(--violet-600);
        }
      }

      &:last-child {
        width: 100%;

        .nav-link {
          display: flex;
          //justify-content: flex-end;
        }
      }

    }
  }
}