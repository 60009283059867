
.client-type-creating-body {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  font-family: Inter, sans-serif;
  color: var(--gray-600);
  //grid-template-areas:
  //  "lastNameProvider middleNameProvider"
  //  "nameProvider nameProvider";
  //
  //.last-name-provider {
  //  grid-area: lastNameProvider;
  //}
  //
  //.name-provider {
  //  grid-area: nameProvider;
  //}
  //
  //.middle-name-provider {
  //  grid-area: middleNameProvider;
  //}
}

.icons-collection-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
}

.holder-label {
  font-size: 18px;
  color: var(--gray-400);
  font-weight: 500;
  font-family: Inter, serif;
}

.error__provider {
  color: red;
  font-size: 12px;
}
