.x-form-select {
  font-family: Inter, sans-serif;
  position: relative;

  &-header {
    min-height: 56px;
    background-color: white;
    border-radius: 32px;
    padding: 12px 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.select-validation-failed {
      border: .25rem solid var(--red-500) !important
    }


    &-content {
      padding-right: 20px;
      color: var(--gray-600);
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;


      .k-form-option-label {
        display: flex;
        font-size: 8px;
        font-weight: 900;
        line-height: 10px;
        font-style: normal;
        align-items: center;
        letter-spacing: 1px;
        color: var(--gray-400);
        text-transform: uppercase;
        font-family: Craftwork Sans, sans-serif;
      }
    }
  }

  &-main {
    position: absolute;
    top: calc(100% + 12px);
    border: 1px solid #E5E7EB;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    width: 100%;
    border-radius: 24px;
    background-color: white;
    z-index: 20;


    &.x-form-select-position-top {
      top: auto;
      bottom: 100%;
    }

    .x-form-select-options {
      max-height: 240px;
      overflow-y: auto;
      padding: 0;
      margin: 0;


      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track-piece:start {
        margin-top: 12px;
      }

      &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 8px;
      }
    }
  }

  .x-form-select-icon {
    //position: absolute;
    //top: 50%;
    //right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(0);
    width: 24px;
    height: 24px;
    transition: transform 0.35s ease-in-out;

    &-open {
      transform: rotateZ(180deg);
    }
  }
}

.k-chip-input-group-wrapper {
  margin-top: 0.25rem;
}