
* {
  font-family: Inter, serif;
  font-style: normal;
  color: var(--gray-600);
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
}
.upload__content {
  width: 100%;
  height: 100%;
  min-height: 5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  p {
    color: var(--gray-600);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 0.25rem;
    margin-bottom: 0;
  }
  &-input {
    outline: none;
    cursor: pointer;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.download__template {
  background-color: var(--gray-100);
  margin-top: 1rem;
  width: 100%;
}
.edit__upload {
  background: var(--gray-200);
  color: var(--gray-600);
  border-radius: 2rem;
}
.upload__content:hover {
  background: #e5e7eb;
}
