
@import "../../assets/scss/reusable/table-depend-on-design";

.contract-details {
  margin-top: 3.5rem;

  &-title {
    font-size: 1.5rem;
    color: var(--gray-400);
    font-family: CraftworkSans, serif;
  }
}

.debtor-view-modal {
  padding: 3rem;
  margin: 0;
}

.modal-title {
  font-size: 1.5rem;
  color: var(--gray-600);
  font-family: CraftworkSans, serif;
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-up.svg") !important;
}

.friend {
  padding: 0.5px 4px 3px 4px;
  border-radius: 50%;
  background: #ede9fe;

  svg {
    height: 13px;
    width: 13px;
  }
}
