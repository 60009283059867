// Fonts
@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-100.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: 900;
}


/* CRAFT-WORK-SANS */
@font-face {
  font-family: 'CraftworkSans';
  src: url("../fonts/CraftworkSans/CraftworkSans-Black.ttf") format("truetype");
  font-weight: 100;
}

/* INTER FONT */
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}
