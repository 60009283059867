
.sort-list {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  //margin-bottom: 1rem;
  gap: 1rem;
  font-family: Inter, sans-serif;
}

.block-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background-color: var(--violet-600);
  color: var(--white);
  border-radius: 1rem;
  min-width: 32px;
  font-size: 13px;
  line-height: 15px;
  margin-right: 0.5rem;
}

.sort-top,
.sort-hide,
.chess-tab {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem 0.5rem;
  color: var(--gray-600) !important;
  font-family: Inter, sans-serif;
}

.sort-top {
  display: flex;
  flex-wrap: wrap;
}

.chess-tab {
  .base__button {
    &.active {
      background-color: var(--violet-600);
      color: var(--white);
    }

    &:hover {
      background-color: var(--violet-600);
      color: var(--white);
    }
  }
}

.sort-hide {
  //top: 100%;
  bottom: 0;
  position: absolute;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  z-index: 9;

  &:after {
    content: "";
    position: absolute;
    width: calc(100% + 3rem);
    left: -2rem;
    bottom: 0;
    height: 2px;
    background-color: var(--gray-300);
  }
}

.detail-button {
  display: flex;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-100);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s linear;

  &.active {
    background-color: var(--violet-600);
  }
}

.filter__price {
  display: flex;
  align-self: stretch;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  max-width: 156px;
  padding-left: 1rem;
  border-left: 2px solid var(--gray-200);
}

.filter-value {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  color: var(--gray-400);
}

.filter__apartment__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //min-width: 20rem;
  height: 3.5rem;
  background-color: var(--gray-100);
  border-radius: 2rem;
  border: 0.25rem solid transparent;
  padding: 0 1.25rem;
  width: 100%;
  max-width: 25rem;
  color: var(--gray-600);
  position: relative;

  ::placeholder {
    color: var(--gray-400);
    opacity: 1;
  }

  .placeholder {
    color: var(--gray-400);
  }

  .input__date {
    margin-left: 0.5rem;
    background-color: transparent;
    border: none;
    width: auto;
  }

  .inline {
    background-color: transparent;
    border: none;
    color: var(--gray-600);
    padding: 0;

    .disabled__option {
      color: var(--gray-100) !important;
    }
  }

  .price__currency {
    min-width: 4rem;
    height: 100%;
  }
}

.filter__inputs-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 20rem;
  min-height: 3.5rem;
  background-color: var(--gray-100);
  border-radius: 2rem;
  padding: 0.5rem 1.25rem;
  width: 100%;
  max-width: 450px;
  border: none;
  color: var(--gray-600);
  position: relative;

  .tag-input {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    border-radius: 2rem;
  }

  ::placeholder {
    color: var(--gray-400);
    opacity: 1;
  }

  .placeholder {
    color: var(--gray-600);
    //padding-left: 1rem;
  }

  .input__date {
    margin-left: 0.5rem;
    background-color: transparent;
    border: none;
  }

  .inline {
    background-color: transparent;
    border: none;
    color: var(--gray-600);
    padding: 0;

    .disabled__option {
      color: var(--gray-100) !important;
    }
  }

  .tag-input__text {
    width: auto !important;
  }
}

::v-deep {
  .b-dropdown .btn:not(.dropdown-item),
  .btn-secondary:not(.dropdown-item) {
    font-family: Inter, sans-serif;
    padding: 1rem 1rem 1rem 1.5rem !important;
    height: 56px;
    font-style: normal;
    line-height: 22px !important;
    border-radius: 2rem !important;
    color: var(--gray-400) !important;
    font-size: 1rem !important;
    border: none !important;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--gray-100) !important;
    margin: 0 !important;
    //width: 100%;

    &:hover {
      -webkit-box-shadow: 0 8px 25px -8px var(--gray-400) !important;
      box-shadow: 0 8px 25px -8px var(--gray-400) !important;
    }

    .input-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .input-label {
      font-weight: 900;
      font-size: 8px;
      line-height: 10px;
      margin: 0 2px 0 0;
      //margin-bottom: 2px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--gray-400);
    }

    .input-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--gray-600);
      margin: 0;
      max-width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .default-label {
      line-height: 22px;
      font-size: 1rem;
      color: var(--gray-400);
      margin: 0;
    }
  }

  .dropdown-toggle::after {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: 1rem;
    background: url("../../assets/icons/icon-down.svg");
    transition: all 0.2s ease-in-out;
  }

  .show .dropdown-toggle::after {
    transform: rotate(-180deg);
  }

  .dropdown-menu {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 0.5rem;

    .dropdown-item {
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      border-radius: 1rem;
      padding: 12px 17px;
      min-width: 256px;

      &:hover {
        background-color: var(--gray-200);
      }
    }
  }

  .b-dropdown-text {
    min-width: 16rem;
    padding: 0.5rem !important;

    .form-group {
      margin-bottom: 0;
    }
  }

  .custom-control-label {
    //padding-left: .5rem;
    //width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 181, 253, 5%);
    box-shadow: 0 0 0 0.2rem rgba(196, 181, 253, 5%);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--violet-600);
    background-color: var(--violet-600);
  }

  .custom-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    //padding: .15rem .15rem .15rem 2.5rem;
    height: 50px;
    font-weight: 600;
    border-radius: 1rem;
    color: var(--gray-600);
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 0.15rem 0.15rem 0.15rem 1rem;

    &:hover {
      background-color: var(--gray-200);
    }
  }

  //@media (max-width: 1200px) {
  //  .b-dropdown .btn:not(.dropdown-item), .btn-secondary:not(.dropdown-item) {
  //    width: auto;
  //  }
  //}
}
