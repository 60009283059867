@font-face {
    font-family: 'Ruberoid Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-Oblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Oblique'), local('../fonts/Ruberoid/Ruberoid-Oblique'),
        url('../fonts/Ruberoid/Ruberoid-Oblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Oblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Oblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Extra';
    src: url('../fonts/Ruberoid/Ruberoid-ExtraLight.eot');
    src: local('../fonts/Ruberoid/Ruberoid Extra Light'), local('../fonts/Ruberoid/Ruberoid-ExtraLight'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLight.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLight.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Bold Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-BoldOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Bold Oblique'), local('../fonts/Ruberoid/Ruberoid-BoldOblique'),
        url('../fonts/Ruberoid/Ruberoid-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-BoldOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-BoldOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-BoldOblique.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid';
    src: url('../fonts/Ruberoid/Ruberoid-Bold.eot');
    src: local('../fonts/Ruberoid/Ruberoid Bold'), local('../fonts/Ruberoid/Ruberoid-Bold'),
        url('../fonts/Ruberoid/Ruberoid-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Bold.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Bold.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Light Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-LightOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Light Oblique'), local('../fonts/Ruberoid/Ruberoid-LightOblique'),
        url('../fonts/Ruberoid/Ruberoid-LightOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-LightOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-LightOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-LightOblique.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Extra';
    src: url('../fonts/Ruberoid/Ruberoid-ExtraBold.eot');
    src: local('../fonts/Ruberoid/Ruberoid Extra Bold'), local('../fonts/Ruberoid/Ruberoid-ExtraBold'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBold.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBold.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Extra Bold Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Extra Bold Oblique'), local('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-ExtraBoldOblique.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Medium Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-MediumOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Medium Oblique'), local('../fonts/Ruberoid/Ruberoid-MediumOblique'),
        url('../fonts/Ruberoid/Ruberoid-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-MediumOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-MediumOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-MediumOblique.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid';
    src: url('../fonts/Ruberoid/Ruberoid-Light.eot');
    src: local('../fonts/Ruberoid/Ruberoid Light'), local('../fonts/Ruberoid/Ruberoid-Light'),
        url('../fonts/Ruberoid/Ruberoid-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Light.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Light.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Extra Light Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-ExtraLightOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Extra Light Oblique'), local('../fonts/Ruberoid/Ruberoid-ExtraLightOblique'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLightOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLightOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLightOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-ExtraLightOblique.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid';
    src: url('../fonts/Ruberoid/Ruberoid-Medium.eot');
    src: local('../fonts/Ruberoid/Ruberoid Medium'), local('../fonts/Ruberoid/Ruberoid-Medium'),
        url('../fonts/Ruberoid/Ruberoid-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Medium.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Medium.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Semi Bold Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-SemiBoldOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Semi Bold Oblique'), local('../fonts/Ruberoid/Ruberoid-SemiBoldOblique'),
        url('../fonts/Ruberoid/Ruberoid-SemiBoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-SemiBoldOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-SemiBoldOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-SemiBoldOblique.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Thin Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-ThinOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Thin Oblique'), local('../fonts/Ruberoid/Ruberoid-ThinOblique'),
        url('../fonts/Ruberoid/Ruberoid-ThinOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-ThinOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-ThinOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-ThinOblique.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Semi';
    src: url('../fonts/Ruberoid/Ruberoid-SemiBold.eot');
    src: local('../fonts/Ruberoid/Ruberoid Semi Bold'), local('../fonts/Ruberoid/Ruberoid-SemiBold'),
        url('../fonts/Ruberoid/Ruberoid-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-SemiBold.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-SemiBold.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid';
    src: url('../fonts/Ruberoid/Ruberoid-Thin.eot');
    src: local('../fonts/Ruberoid/Ruberoid Thin'), local('../fonts/Ruberoid/Ruberoid-Thin'),
        url('../fonts/Ruberoid/Ruberoid-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Thin.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Thin.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid';
    src: url('../fonts/Ruberoid/Ruberoid-Regular.eot');
    src: local('../fonts/Ruberoid/Ruberoid'), local('../fonts/Ruberoid/Ruberoid-Regular'),
        url('../fonts/Ruberoid/Ruberoid-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-Regular.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-Regular.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ruberoid Ultra Light Oblique';
    src: url('../fonts/Ruberoid/Ruberoid-UltraLightOblique.eot');
    src: local('../fonts/Ruberoid/Ruberoid Ultra Light Oblique'), local('../fonts/Ruberoid/Ruberoid-UltraLightOblique'),
        url('../fonts/Ruberoid/Ruberoid-UltraLightOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-UltraLightOblique.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-UltraLightOblique.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-UltraLightOblique.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Ruberoid Ultra';
    src: url('../fonts/Ruberoid/Ruberoid-UltraLight.eot');
    src: local('../fonts/Ruberoid/Ruberoid Ultra Light'), local('../fonts/Ruberoid/Ruberoid-UltraLight'),
        url('../fonts/Ruberoid/Ruberoid-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ruberoid/Ruberoid-UltraLight.woff2') format('woff2'),
        url('../fonts/Ruberoid/Ruberoid-UltraLight.woff') format('woff'),
        url('../fonts/Ruberoid/Ruberoid-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

