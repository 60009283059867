
.cell {
  width: 100%;
}

.error__provider {
  color: red;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0rem;
}

::v-deep .reContract {
  &-header {
    display: flex;
    align-items: center !important;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    border-top: 6px solid var(--gray-100);
    border-bottom: 6px solid var(--gray-100);
    padding: 20px 0;

    .nav-item {
      min-width: max-content;

      .nav-link {
        padding: 8px 6px;
      }

      .active {
        background-color: transparent;
        color: var(--violet-600);

        p {
          color: var(--violet-600);
        }

        span {
          background-color: var(--violet-100);
          color: var(--violet-600);
        }
      }

      &:last-child {
        width: 100%;

        .nav-link {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.tab-title {
  display: flex;
  justify-content: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    line-height: 20px;
    margin-right: 0.5rem;
    background-color: var(--gray-100);
    color: var(--gray-400);
  }

  p {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    color: var(--gray-400);
  }

  .next-icon {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}

.assignee {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  ::v-deep .base-input {
    input {
      font-family: Inter, sans-serif;
      font-weight: 700 !important;
    }

    .input-label span {
      top: 6px !important;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    padding: 1rem 1.25rem;
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: var(--gray-400);

    &__title {
      text-transform: uppercase;
      margin: 0;
    }
  }

  .row {
    margin: 0 auto;
    width: 100%;
    border-bottom: 2px solid var(--gray-200);

    &:last-child {
      border-bottom: none;
    }
  }

  &-item {
    display: flex;

    //:last-child {
    //  border: none;
    //}
    .select {
      max-height: 56px;
      margin: 1.25rem auto;
    }

    .data-picker {
      height: 56px;
      max-height: 56px;
      width: 100%;
      margin: 1.25rem auto;
      border: 2px solid #e5e7eb;
      border-radius: 32px;
    }

    ::v-deep .base-calendar .date-picker .mx-input-wrapper .mx-input {
      height: 52px !important;
      padding: 0.5rem 0.5rem 0.5rem 16px !important;
      width: 100%;
      font-weight: 700;
      font-size: 1rem;
      color: var(--gray-600);
    }

    ::v-deep .base-calendar {
      .input-label {
        font-family: CraftworkSans, serif;
        position: absolute;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--gray-400);
        width: 100%;
        display: flex;
        padding-left: 1rem;
        margin-bottom: 10px;
        z-index: 3;

        span {
          font-weight: 900;
          font-size: 8px;
          line-height: 10px;
          position: absolute;
          top: 6px;
          left: 20px;
        }
      }
    }

    .form-control:disabled {
      background-color: #fff;
    }

    .client__details {
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 1.5rem;
        line-height: 28px;
        color: var(--gray-400);
        margin: 2rem 0 1rem 0;
        width: 100%;
        max-width: 40rem;
      }

      &_info {
        //display: grid;
        //grid-template-columns: 1fr 1fr;
        //column-gap: 3rem;

        &_card {
          display: flex;
          align-items: center;
          width: 100%;
          //max-width: 40rem;
          height: 56px;
          border: 2px solid #e5e7eb;
          border-radius: 32px;
          padding: 0 20px;
          position: relative;
          margin: 1.25rem auto;

          & svg {
            position: absolute;
            right: 20px;
            //z-index: 100;
          }

          & label {
            text-transform: uppercase;
            margin: 0;
            padding-right: 10px;
            color: var(--gray-400);
            white-space: nowrap;
            font-size: 0.8rem;
            letter-spacing: 1px;
          }

          input {
            font-family: CraftworkSans, serif;
            height: 100%;
            width: 100%;
            border: none;
            text-align: right;
            padding-right: 4px;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            color: var(--gray-600);
          }

          select {
            width: 140px;
            height: 24px;
            padding: 0;
            margin-top: 0 !important;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            right: 20px;
            border: none;

            option {
              padding: 20px;
              border: none;
            }
          }
        }
      }
    }
  }
}

.agree {
  &-row {
    flex-direction: column;
    row-gap: 1rem;
  }

  &-item {
    .data-picker {
      height: 56px;
      max-height: 56px;
      width: 100%;
      border: 2px solid #e5e7eb;
      border-radius: 32px;
    }

    ::v-deep .base-calendar .date-picker .mx-input-wrapper .mx-input {
      height: 52px !important;
      padding: 0.5rem 0.5rem 0.5rem 20px !important;
      width: 100%;
      font-weight: 700;
      font-size: 1rem;
      color: var(--gray-600);
    }

    .form-control:disabled {
      background-color: #fff;
    }
  }
}

.input-price-group {
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  border-radius: 2rem;
  background-color: var(--gray-100);
  color: var(--gray-600);

  .price-from,
  .price-to {
    height: 100%;
    padding: 0 1.25rem;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border: none;
    font-family: inherit;
    color: inherit;

    &::placeholder {
      font-family: Inter, sans-serif;
      color: var(--gray-400);
      font-weight: 600;
    }
  }

  .price-from {
    width: 30%;
    //border-right: 1px solid var(--gray-300);
    border-right: 1px solid var(--gray-300);
    border-radius: 0 !important;
    padding-right: 0;
  }

  .price-to {
    width: 70%;
  }
}
