
.form__radio {
  background-color: var(--background-color-dropdown);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1rem;
}

.error__provider {
  color: red;
}
