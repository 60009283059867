
.error__provider {
  color: red;
  display: block;
  margin-bottom: 1rem;
}

.save__button {
  color: white;
}

::v-deep #modal-create {
  background: rgba(173, 177, 186, 0.32);
  backdrop-filter: blur(2px);

  .modal-dialog {
    .modal-content {
      border-radius: 50px;
      padding: 20px;

      header {
        border-bottom: none;
        display: flex;
        align-items: center;

        h5 {
          font-size: 36px;
          color: #4b5563;
        }

        button {
          background: #f3f4f6;
          border-radius: 50%;
          padding: 12px 18px;
        }
      }

      .modal-body {
        fieldset {
          div {
            border-radius: 50px;
          }
        }
      }
    }
  }
}

.select_input {
  .base-input {
    width: 100%;

    ::v-deep .input-label span {
      top: -5px !important;
    }
  }
}

.footer-btn {
  .btn {
    width: 50%;
  }

  .btn:nth-child(2) {
    background: linear-gradient(88.25deg, #7c3aed 0%, #818cf8 100%) !important;
  }
}

.select_custom {
  ::v-deep span {
    color: #9ca3af;
  }
}
