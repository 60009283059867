
.price-input-content {
  position: relative;
}

.top-placeholder {
  font-size: 8px;
  line-height: 10px;
  position: absolute;
  top: -3px;
  text-transform: uppercase;
  font-family: CraftworkSans, serif;
  font-weight: 900;
}

.price-input {
  border: none;
  max-width: 80%;
  background-color: transparent;

  &::placeholder {
    color: var(--gray-400);
  }
}

.delete-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform ease-in-out;
}

.hide-content {
  visibility: hidden;
}

.show-content {
  color: var(--gray-400);
  visibility: visible;
  padding-top: 0.5rem;
  font-weight: 900;
}
