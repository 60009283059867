
.info {
  margin-bottom: 2rem;

  &-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    column-gap: 2rem;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    flex: calc(50% - 2rem);
    max-width: calc(50% - 1rem);

    .label {
      color: var(--gray-400);
      margin-right: 1rem;
    }

    .value {
      margin: 0;
      color: var(--gray-600);
    }
  }
}
