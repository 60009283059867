
.date__range__picker {
  min-width: 360px;

  ::v-deep .form-control {
    border: none;
    border-radius: 2rem;
    background-color: var(--gray-100);
    height: auto;
    padding: 0;
  }
}

.date__range__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: Inter, sans-serif;

  .date__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem 0.75rem 1.25rem;

    &__start {
      border-right: 2px solid var(--gray-300);
    }

    &__title {
      color: var(--gray-400);
      font-size: 0.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &__value {
      color: var(--gray-600);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__field {
      display: flex;
      flex-direction: column;
    }
  }
}

.ranges__item {
  color: var(--gray-600);
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
