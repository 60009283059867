
.base__card {
  border: none;
  font-style: normal;
  font-family: Inter, sans-serif;
  max-width: 20rem;
  min-width: 10rem;

  &__body {
    border: 2px solid var(--gray-200);
    border-radius: 2rem;
    padding: 1.5rem;
  }

  &__body__top {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
  }

  &__title {
    color: var(--gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.25rem;

    &.bottom__flex__column {
      flex-direction: column;
    }

    &__title {
      color: var(--gray-400);
      font-weight: 600;
      line-height: 22px;
    }

    &__value {
      color: var(--gray-600);
      font-weight: 700;
      line-height: 28px;
    }

    &__primary__value {
      color: var(--violet-600);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }
}

.active__value {
  color: var(--violet-600);
  font-weight: 700;
  line-height: 28px;
}
