
.navigation-content {
  display: flex;
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
}

::v-deep .navigation-date-picker {
  position: relative;
  width: 100%;

  .mx-icon-calendar,
  .mx-icon-clear,
  .mx-input {
    visibility: hidden;
  }

  .output-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-text {
      font-family: CraftworkSans, serif;
      color: var(--gray-600);
      font-size: 1.5rem;
      white-space: nowrap;
    }
  }
}
