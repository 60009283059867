
hr {
  margin: 26px 0;
  border: 0.8px solid #e5e7eb;
  background: #e5e7eb;
}

.stamp {
  background: #fff;
  width: max-content;
  padding: 5px 12px;
  margin-top: 12px !important;
  color: #9ca3af;
  border: none;
  border-radius: 32px;
}

.payment__content-detail {
  border: none !important;
  border-radius: 30px;
  padding: 1.5rem;
  font-size: 14px;
  width: auto;
  background: #f3f4f6;
  color: #9ca3af;
  min-width: 28rem;

  .dropdown-menu {
    border: none;
    border-radius: 20px;
    padding: 20px 12px;
    min-width: 15rem;

    button {
      margin-bottom: 30px;
    }

    button:nth-child(3) {
      margin-bottom: 0;
    }
  }

  p {
    display: flex;
    gap: 5px;
    color: #9ca3af;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  span:nth-child(1) {
    span {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      p {
        font-family: CraftworkSans, serif;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        margin-bottom: 0;
        color: #7c3aed;
      }

      .float-right {
        .dropdown {
          button {
            background: transparent;
            box-shadow: none;

            i {
              color: #4b5563;
            }
          }
        }
      }
    }
  }
}

.active__payment__content {
  background-color: #7c3aed;
  color: white;
}

.make__primary {
  display: flex;
  justify-content: flex-end;
}
