
@import "@/assets/scss/utils/pagination.scss";
.animation {
  animation: 1s spin infinite linear;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.statistics-detail {
  display: none;
}
.statistics:hover .statistics-detail {
  display: block;
}
.label {
  color: #7c3aed;
  margin-right: 3px;
  font-weight: 500;
}

.fal {
  font-weight: 500;
}

.text {
  font-weight: 600;
}

.button {
  height: auto;
  background-color: #7c3aed;
  width: auto;
  padding: 8px;

  ::v-deep span {
    margin-left: 0 !important;
  }
}

.actions {
  display: flex;
  gap: 16px;
}

.search__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

::v-deep .row__head__bottom-border {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .row__body__bottom-border:not(:last-child) {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .table__list {
  min-height: 250px;
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.125rem 1rem;
      vertical-align: middle;

      //&.b-table-sort-icon-left {
      //display: flex;
      //align-items: center;
      //}
    }

    td {
      font-family: Inter, sans-serif;
      font-size: 1rem;
      line-height: 22px;
      font-weight: 600;
      vertical-align: middle;
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  //background-position: right !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-up.svg") !important;
}

.options-container {
  border: 1px solid #e5e7eb;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  background-color: white;
  z-index: 20;
  max-height: 260px;
  overflow-y: auto;
}
.contract-option {
  margin: 8px;
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}
.contract-option:hover {
  background-color: var(--violet-100);
  color: var(--violet-600);
}
