.x-font-size-05 {
  font-size: 0.8rem;
}

.x-font-size-1{
  font-size: 1rem;
}

.x-font-size-1p5{
  font-size: 1.5rem;
}

.x-font-size-2{
  font-size: 2rem;
}

.x-font-size-2p5{
  font-size: 2.5rem;
}

.x-font-size-3{
  font-size: 3rem;
}

.x-font-size-3p5{
  font-size: 3.5rem;
}

.x-font-size-4{
  font-size: 4rem;
}

.x-font-size-4p5{
  font-size: 4.5rem;
}

.x-font-size-5{
  font-size: 5rem;
}

.x-font-size-36px{
  font-size: 36px;
}



