
.exit {
  padding: 7px;
  border-radius: 100%;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-input {
    width: 50%;
    height: 3.5rem;
    border-radius: 2rem;
    background-color: var(--gray-100);
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    position: relative;
  }

  &-add {
    height: 100%;
    width: 250px;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    background-color: var(--gray-100);
    margin-left: 0.5rem;
    cursor: pointer;

    .plus__icon {
      margin-right: 1.25rem;
    }
  }

  input {
    width: 100%;
    background-color: transparent;
    margin-left: 1.25rem;
    height: 100%;
    outline: none;
    border: none;
    font-weight: 700;
    color: var(--gray-600);

    &::placeholder {
      font-weight: 600;
      color: var(--gray-400);
    }
  }
}
