
.price-input-content {
  position: relative;
}

.top-placeholder {
  font-family: Inter, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.6rem;
  position: absolute;
  top: -1px;
  left: 24px;
  color: var(--gray-400);
}

.price-input {
  border: none;
  background-color: transparent;
  width: 100%;
  font-weight: 700;
  &::placeholder {
    color: var(--gray-400);
  }
}
.mt5 {
  margin-top: 5px;
}
.delete-button {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform ease-in-out;
}

.hide-content {
  visibility: hidden;
}

.show-content {
  color: var(--gray-400);
  visibility: visible;
  padding-top: 0.5rem;
  font-weight: 700;
}
