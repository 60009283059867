
.submit__button {
  margin: 0 !important;
}

.cancel__button {
  margin: 0 1rem 0 0 !important;
}

.save__button {
  color: white;
}

.error__provider {
  color: red;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
