.text-black {
  color: black;
}

.text-white{
  color: white;
}

/***
 ?*****************************
 *****************************
         ?VIOLET
 *****************************
 ?*****************************
 ***/

.violet-50 {
  color: var(--violet-50);
}

.violet-100 {
  color: var(--violet-100);
}

.violet-200 {
  color: var(--violet-200);
}

.violet-300 {
  color: var(--violet-300);
}

.violet-400 {
  color: var(--violet-400);
}

.violet-500 {
  color: var(--violet-500);
}

.violet-600 {
  color: var(--violet-600);
}

.violet-700 {
  color: var(--violet-700);
}

.violet-800 {
  color: var(--violet-800);
}

.violet-900 {
  color: var(--violet-900);
}


/***
 ?*****************************
 *****************************
         ?GRAY
 *****************************
 ?*****************************
 ***/

.gray-50 {
  color: var(--gray-50);
}

.gray-100 {
  color: var(--gray-100);
}

.gray-200 {
  color: var(--gray-200);
}

.gray-300 {
  color: var(--gray-300);
}

.gray-400 {
  color: var(--gray-400);
}

.gray-500 {
  color: var(--gray-500);
}

.gray-600 {
  color: var(--gray-600);
}

.gray-700 {
  color: var(--gray-700);
}

.gray-800 {
  color: var(--gray-800);
}

.gray-900 {
  color: var(--gray-900);
}

/***
 ?*****************************
 *****************************
         !RED
 *****************************
 ?*****************************
 ***/

.red-50 {
  color: var(--red-50);
}

.red-100 {
  color: var(--red-100);
}

.red-200 {
  color: var(--red-200);
}

.red-300 {
  color: var(--red-300);
}

.red-400 {
  color: var(--red-400);
}

.red-500 {
  color: var(--red-500);
}

.red-600 {
  color: var(--red-600);
}

.red-700 {
  color: var(--red-700);
}

.red-800 {
  color: var(--red-800);
}

.red-900 {
  color: var(--red-900);
}

/***
 ?*****************************
 *****************************
         ORANGE
 *****************************
 ?*****************************
 ***/

.orange-50 {
  color: var(--orange-50);
}

.orange-100 {
  color: var(--orange-100);
}

.orange-200 {
  color: var(--orange-200);
}

.orange-300 {
  color: var(--orange-300);
}

.orange-400 {
  color: var(--orange-400);
}

.orange-500 {
  color: var(--orange-500);
}

.orange-600 {
  color: var(--orange-600);
}

.orange-700 {
  color: var(--orange-700);
}

.orange-800 {
  color: var(--orange-800);
}

.orange-900 {
  color: var(--orange-900);
}


/***
 ?*****************************
 *****************************
         YELLOW
 *****************************
 ?*****************************
 ***/

.yellow-50 {
  color: var(--yellow-50);
}

.yellow-100 {
  color: var(--yellow-100);
}

.yellow-200 {
  color: var(--yellow-200);
}

.yellow-300 {
  color: var(--yellow-300);
}

.yellow-400 {
  color: var(--yellow-400);
}

.yellow-500 {
  color: var(--yellow-500);
}

.yellow-600 {
  color: var(--yellow-600);
}

.yellow-700 {
  color: var(--yellow-700);
}

.yellow-800 {
  color: var(--yellow-800);
}

.yellow-900 {
  color: var(--yellow-900);
}


/***
 ?*****************************
 *****************************
         GREEN
 *****************************
 ?*****************************
 ***/

.green-50 {
  color: var(--green-50);
}

.green-100 {
  color: var(--green-100);
}

.green-200 {
  color: var(--green-200);
}

.green-300 {
  color: var(--green-300);
}

.green-400 {
  color: var(--green-400);
}

.green-500 {
  color: var(--green-500);
}

.green-600 {
  color: var(--green-600);
}

.green-700 {
  color: var(--green-700);
}

.green-800 {
  color: var(--green-800);
}

.green-900 {
  color: var(--green-900);
}


/***
 ?*****************************
 *****************************
         TEAL
 *****************************
 ?*****************************
 ***/

.teal-50 {
  color: var(--teal-50);
}

.teal-100 {
  color: var(--teal-100);
}

.teal-200 {
  color: var(--teal-200);
}

.teal-300 {
  color: var(--teal-300);
}

.teal-400 {
  color: var(--teal-400);
}

.teal-500 {
  color: var(--teal-500);
}

.teal-600 {
  color: var(--teal-600);
}

.teal-700 {
  color: var(--teal-700);
}

.teal-800 {
  color: var(--teal-800);
}

.teal-900 {
  color: var(--teal-900);
}

/***
 ?*****************************
 *****************************
         LIGHT-BLUE
 *****************************
 ?*****************************
 ***/

.light-blue-50 {
  color: var(--light-blue-50);
}

.light-blue-100 {
  color: var(--light-blue-100);
}

.light-blue-200 {
  color: var(--light-blue-200);
}

.light-blue-300 {
  color: var(--light-blue-300);
}

.light-blue-400 {
  color: var(--light-blue-400);
}

.light-blue-500 {
  color: var(--light-blue-500);
}

.light-blue-600 {
  color: var(--light-blue-600);
}

.light-blue-700 {
  color: var(--light-blue-700);
}

.light-blue-800 {
  color: var(--light-blue-800);
}

.light-blue-900 {
  color: var(--light-blue-900);
}

/***
 ?*****************************
 *****************************
         BLUE
 *****************************
 ?*****************************
 ***/

.blue-50 {
  color: var(--blue-50);
}

.blue-100 {
  color: var(--blue-100);
}

.blue-200 {
  color: var(--blue-200);
}

.blue-300 {
  color: var(--blue-300);
}

.blue-400 {
  color: var(--blue-400);
}

.blue-500 {
  color: var(--blue-500);
}

.blue-600 {
  color: var(--blue-600);
}

.blue-700 {
  color: var(--blue-700);
}

.blue-800 {
  color: var(--blue-800);
}

.blue-900 {
  color: var(--blue-900);
}

/***
 ?*****************************
 *****************************
         PINK
 *****************************
 ?*****************************
 ***/

.pink-50 {
  color: var(--pink-50);
}

.pink-100 {
  color: var(--pink-100);
}

.pink-200 {
  color: var(--pink-200);
}

.pink-300 {
  color: var(--pink-300);
}

.pink-400 {
  color: var(--pink-400);
}

.pink-500 {
  color: var(--pink-500);
}

.pink-600 {
  color: var(--pink-600);
}

.pink-700 {
  color: var(--pink-700);
}

.pink-800 {
  color: var(--pink-800);
}

.pink-900 {
  color: var(--pink-900);
}

.dropdown-icon-color {
  color: var(--dropdown-icon);
}



