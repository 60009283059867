$default-color: #555;
$primary-color: #7C3AED;

@import '../../../../node_modules/vue2-datepicker/scss/index';
  .cell {
    &.active {
      border: solid 0 $primary-color;
      //border-radius: 0 10px 10px 0;
      border-radius: 5px;
    }
    &.active:nth-child(2) {
      //border-radius: 10px 0 0 10px;
      border-radius: 5px;
    }
}
