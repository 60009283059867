

::v-deep .main__content
    .slider__image
        object-fit: contain

::v-deep .slider-content
    margin: 1rem
    //margin-left: 1rem
    //margin-right: 1rem
    //margin-bottom: 1rem

    .swiper-container
        display: flex
        align-items: center
        justify-content: center
        height: 24rem

        .swiper-slide
            cursor: grab
            display: flex
            justify-content: center
            align-items: center
            padding: 0 58px

            .swiper-image
                width: 100%
                max-height: 19rem
                object-fit: contain
        //height: 16rem

        .swiper-button
            width: 3rem
            height: 3rem
            border-radius: 50%
            background-color: var(--gray-100)

        .swiper-button-next::after,
        .swiper-button-prev::after
            content: none

    .swiper-pagination
        margin-top: 3rem

        &-bullets
            bottom: 1rem

        &-bullet
            width: 0.75rem
            height: 0.75rem
            margin-right: 0.3rem
            background-color: var(--gray-400)

            &-active
                background-color: var(--violet-400)

.apartment-promo-icon
    display: flex
    //height: auto
    height: 24px
    min-width: 32px

    img
        width: 100%

.plan-text
    display: flex
    flex-direction: column
    font-family: Inter, sans-serif
    margin-bottom: 1.5rem

    h5
        font-weight: 600
        font-size: 18px
        line-height: 24px
        color: var(--gray-600)
        margin-bottom: 1rem

        sup
            color: var(--gray-600)

    p
        font-weight: 600
        font-size: 16px
        line-height: 22px
        color: var(--gray-400)
        margin-bottom: 0

        sup
            color: var(--gray-400)

.plans
    display: flex
    flex-direction: column
    row-gap: 1rem

.plan-block
    font-family: Inter, sans-serif
    display: flex
    justify-content: space-between
    background: #F3F4F6
    border-radius: 32px
    padding: 24px 32px
    cursor: pointer

    &:hover
        background-color: var(--violet-100)

    .plan-item
        h5
            font-weight: 600
            font-size: 24px
            line-height: 30px
            margin-bottom: .5rem
            color: var(--gray-600)

            sup
                color: var(--gray-600)

        p
            font-weight: 600
            font-size: 16px
            line-height: 22px
            margin-bottom: 0
            color: var(--gray-500)

            sup
                color: var(--gray-500)
