.border-radius-1{
  border-radius: 1rem;
}

.border-radius-2{
  border-radius: 2rem;
}

.border-radius-3{
  border-radius: 3rem;
}