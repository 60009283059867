
.home__pie__card {
  //width: max-content;
  background-color: var(--gray-50);
  border-radius: 2rem;
  padding: 1.5rem;
  border: none;
  color: var(--gray-600);

  &__title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    //flex-wrap: wrap;
    //flex-direction: column;
    //justify-content: center;
    gap: 3rem;
    margin: 2rem;
    //margin: 1.5rem 1.5rem 2rem 2rem;
  }
}

.loading__spinner {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--violet-600);
}

@mixin th__base {
  color: #9CA3AF;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
}

::v-deep .home__table__list {
  //max-width: 30rem;
  width: 100%;
  overflow-y: hidden;

  .table__head__tr {
    border-bottom: 2px solid var(--gray-200);

    th.table-b-table-default {
      @include th__base
    }
  }

  .table__body__tr {
    @include th__base
  }
}

@media screen and (max-width: 1200px) {
  .home__pie__card {
    &__body {
      flex-direction: column;
      align-items: center;
    }
  }
}
