
*
  font-family: Inter, serif
  font-style: normal
  line-height: 22px
  color: var(--gray-600)
  font-weight: 600

.color-violet-600
  color: var(--violet-600)

.violet__design
  background-color: var(--violet-100)
  color: var(--violet-600)

.gray__design
  background-color: var(--gray-100)
  color: var(--gray-600)

.gray__400
  color: var(--gray-400)

.main__class
  background-color: white
  padding: 3rem
  min-height: 100vh
  color: var(--gray-600)

.navigation__content
  display: flex
  align-items: center
  //margin-bottom: 52px

  .go__back
    width: 56px
    height: 56px
    border-radius: 100%
    background-color: var(--gray-100)
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

  .breadcrumb__content
    display: inline-flex
    flex-direction: column
    margin-left: 1rem
    font-weight: 600
    font-size: 14px
    color: #9CA3AF

  .head
    font-size: 24px
    line-height: 28px
    color: #4B5563

    .contract__number
      color: var(--violet-600)

.navigation__links
  padding: 1rem 3rem
  border-top: 6px solid var(--gray-100)
  border-bottom: 6px solid var(--gray-100)
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

  .links
    display: flex
    align-items: center

    .link
      display: flex
      align-items: center
      cursor: pointer

      span:first-child
        border-radius: 50%
        width: 2rem
        height: 2rem
        display: flex
        justify-content: center
        align-items: center
        margin-right: 0.5rem

.next__button
  background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%)
  color: #FFFFFF

.reload__button
  background: var(--gray-100)
  color: var(--gray-600)

.main__content
  padding: 2rem 3rem

::v-deep .table__list
  max-height: none

  table
    color: var(--gray-600)

    thead tr th
      font-family: CraftworkSans, serif
      font-weight: 600
      line-height: 14px
      letter-spacing: 1px
      color: var(--gray-400) !important
      padding: 1.25rem 1rem

    td
      vertical-align: middle

  .table.b-table[aria-busy="true"]
    opacity: 1 !important

::v-deep .row__head__bottom-border
  border-bottom: 2px solid var(--gray-200) !important

::v-deep .row__body__bottom-border:not(:last-child)
  border-bottom: 2px solid var(--gray-200) !important

.header
  display: flex
  width: 100%
  justify-content: space-between
  border-bottom: 2px solid var(--gray-200)
  //padding-left: 1rem
  font-size: 1rem

  .row
    color: var(--gray-400)
    font-family: CraftworkSans, serif
    display: flex
    align-items: center
    justify-content: flex-start
    width: 100%
    height: 3.5rem
    letter-spacing: 1px
    text-transform: uppercase
//padding-left: 1rem

.main__section
  .main__row
    color: var(--gray-600)
    font-size: 1.2rem
    font-family: Inter, serif
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-between
    width: 100%

    .cell
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      padding-top: 1rem
      padding-bottom: 1rem
      //padding-left: 1rem
      border-bottom: 2px solid var(--gray-200)

      &__item
        display: flex
        justify-content: flex-start
        width: 100%
        padding: 1rem 0

.warning__border
  border: 2px solid var(--red-500)

.upload__list
  padding-top: 2rem
  padding-bottom: 2rem

::v-deep .table__list
  max-height: none
  margin-top: 2rem

  table
    color: var(--gray-600)

    thead tr th
      font-family: CraftworkSans, serif
      font-weight: 600
      line-height: 14px
      letter-spacing: 1px
      color: var(--gray-400) !important
      padding: 1.25rem 1rem 1.25rem 0.75rem

    td
      vertical-align: middle

  .table.b-table[aria-busy=true]
    opacity: 1 !important

  .empty__scope
    font-size: 1.5rem
    min-height: 30rem

::v-deep .row__head__bottom-border
  border-bottom: 2px solid var(--gray-200) !important

::v-deep .row__body__bottom-border:not(:last-child)
  border-bottom: 2px solid var(--gray-200) !important
