
@import "./styles/tabs-style";

.app-checkout-main {
  margin-top: 2rem;
}

.checkout-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 30px;
  color: var(--white);
  border-radius: 28px;
  height: 56px;
  min-width: 102px;

  &-inner {
    padding: 13px 16px;
  }
}

.ch-comment-body {
  font-family: Inter, sans-serif;
  margin-top: 3rem;
  margin-bottom: 3rem;

  &-comment {
    margin-bottom: 1rem;
  }
}

.warning-before-cancel-wrapper {
  margin: 3.5rem 0;
  color: var(--gray-600);
  font-family: Inter, sans-serif;
}

.apn-result {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  &__item {
    &-content {
      display: flex;
      column-gap: 3rem;

      .apartment-details-content,
      .pd-payment-result {
        flex-basis: 50%;
      }
    }
  }

  &__total {
    display: flex;
    padding: 3rem 0;
    align-items: center;
    justify-content: space-between;
    border-top: 6px dashed var(--gray-200);
    //background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);;
    //background-position: bottom;
    //background-repeat: repeat-x;
    //background-size: 16px 16px;

    p {
      font-family: CraftworkSans, serif;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
      color: var(--violet-700);
      margin-bottom: 0;
    }
  }
}
