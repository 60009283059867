
@import "../../assets/scss/reusable/debtors-ui";

.week-content {
  min-height: 45rem;
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(7, 1fr);
  font-family: CraftworkSans, serif;
  gap: 0;

  &-column {
    justify-self: stretch;
    border: 1px solid var(--gray-100);
    //min-width: 16rem;
    min-height: 8.5rem;
    position: relative;
    overflow: hidden;

    .cell-top-content {
      //width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.75rem;

      .day-of-month {
        color: var(--gray-400);
      }

      .day-of-week {
        color: var(--gray-600);
      }
    }

    .cell-middle-content {
      display: block;
      background-color: var(--violet-600);
      border-radius: 0.25rem;
      margin: 0 0.25rem;
      color: white;
      padding: 0.5rem;
      font-size: 14px;
      max-height: 100px;
      text-overflow: ellipsis;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      white-space: nowrap;
      line-break: anywhere;
      overflow: hidden;
    }
  }

  .week-debts-column {
    height: 45rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.today-cell {
  background-color: var(--violet-50);

  .day-of-month,
  .day-of-week {
    color: var(--violet-600) !important;
  }
}

.view-more-details {
  padding: 1rem;
  color: var(--gray-600);
  background-color: var(--gray-50);
}
