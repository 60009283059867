
@import "@/assets/scss/utils/pagination";
.relative {
  position: relative;
}
.block-info {
  border-radius: 10%;
  width: fit-content;
  position: absolute;
  top: 100%;
  display: none;
  background-color: var(--blue-100);
  padding: 0.3rem 2rem;
}

.search__content {
  margin-top: 0;
}

.base-search-input {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.phone-col {
  display: flex;
  width: max-content;
}

::v-deep .row__head__bottom-border {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .row__body__bottom-border:not(:last-child) {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .table__list {
  min-height: 250px;
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.125rem 1rem;
      vertical-align: middle;

      //&.b-table-sort-icon-left {
      //display: flex;
      //align-items: center;
      //}
    }

    td {
      vertical-align: middle;
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  //background-position: right !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../../assets/icons/icon-arrow-up.svg") !important;
}

.content__form__select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-100);
  border-radius: 2rem !important;
  border: none;
  color: var(--gray-600);
  position: relative;

  .form__select {
    background-color: transparent;
    border: none;
    color: var(--gray-600);
    margin: 0 1rem;
    width: 100%;
  }

  ::v-deep .x-form-select-main .x-form-select-options {
    max-height: 200px !important;
  }
}
