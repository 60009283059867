
.secondary__cards {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem;
  }

  &__spinner {
    width: 2rem;
    height: 2rem;
    //border: 20px solid var(--violet-600);
  }
}

.widget__grow__2{
  flex-grow: 2;
}
