
.chart__loading__spinner{
  width: 1.5rem;
  height: 1.5rem;
  color: var(--violet-600);
}

.chart__title{
  color: var(--gray-600);
  font-family: Inter,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
