
.sales__details {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;

  .item__title {
    color: var(--gray-400);
  }

  .item__value {
    color: var(--gray-600);
    font-weight: 600;
  }
}
