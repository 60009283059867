
.basket {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto -3rem;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem 2rem 0 0;
  padding: 12px 32px;
  z-index: 9;
  animation: fade-top 0.5s linear;
  background-color: var(--violet-600);

  &-block {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    ::v-deep .base-checkbox > input {
      background-color: white !important;

      &::before {
        background: #7c3aed;
        height: 8px;
      }
    }
  }

  .violet400 {
    background-color: var(--violet-400);
    color: var(--white);
  }

  p {
    margin: 0;
    padding: 0;
    color: var(--white);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .base__button {
    padding: 13px 24px;
    font-family: "Inter", serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  @keyframes fade-top {
    0% {
      opacity: 0;
      transform: rotateX(90deg);
      bottom: -10px;
    }
    100% {
      opacity: 1;
      transform: rotateX(0);
      bottom: 0;
    }
  }
}
