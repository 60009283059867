
.row-gap-1 {
  row-gap: 1rem;
}

.edit-icon-wrapper {
  padding: 5.25px;
  background-color: var(--violet-600);
  width: 24px;
  height: 24px;
  margin-left: 1rem;
}

.go__back {
  cursor: pointer;
}
