
.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
}

.footer-btn {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.error__provider {
  color: red;
  display: block;
  margin-bottom: 1rem;
}

.save__button {
  color: white;
}

.create-layout {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.uploader {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 176px;
  max-height: 132px;
  min-height: 200px;
  border: 2px solid #f3f4f6;
  padding: 0;
  overflow: hidden;
  position: relative;

  .action-list {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
  }

  .delete-plan {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--red-600);
    cursor: pointer;
  }

  .drag-plan {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-400);
    cursor: pointer;
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    //width: 100%;
  }
}

.uploader-row {
  width: 100%;
  row-gap: 0.5rem;

  .col-3 {
    padding: 0 4px !important;

    .uploader {
      max-width: 176px;
      max-height: 132px;
      min-height: 132px;

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }
}

.uploader-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .row-item {
    display: flex;
    gap: 8px;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;

    &-2 {
      height: 75%;
    }
  }

  .active-uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    p {
      color: #4b5563;
      font-weight: 600;
      font-family: Inter, serif;
      line-height: 22px;
    }
  }
}
