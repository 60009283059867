
.app-breadcrumb {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 600;
  font-family: Inter, sans-serif;
  display: flex;
  align-items: center;
  //justify-content: center;
  column-gap: 0.25rem;

  &-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      color: var(--violet-600);
    }

    &-content:not(:first-child) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &-page {
    //padding-left: 10px;
    //padding-right: 10px;
  }
}

.app-header {
  font-family: Inter, sans-serif;

  &-back-button {
    width: 56px;
    height: 56px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--gray-100);

    &:hover {
      background-color: var(--gray-200);
    }
  }

  &-page-content {
    margin-left: 1rem;
  }

  &-page-title {
    display: flex;
    column-gap: 0.5rem;
    font-size: 24px;
    font-weight: 900;
    color: var(--gray-600);
    font-family: CraftworkSans, serif;

    &-active {
      color: var(--violet-600);
    }
  }
}
