// Colors
$white: #fff;
$primary: #5851d8;
$success: #45a85c;
$info: #0c5460;
$warning: #ff9f43;
$danger: #dc3545;
$light: #bebebe;
$secondary: #82868b;
$dark: #283046;
$dark-secondary: #3b4253;


