
//::v-deep .promo__multiselect__tag {
//  .multiselect {
//    &__tags {
//      height: 3.25rem;
//    }
//
//    &__select {
//    }
//  }
//}

.prepay__each__content {
  background-color: var(--background-color-dropdown);
  border-radius: 10px;
  position: relative;
}

.prepay__input__content {
  input {
    height: 40px !important;
  }

  .percent__icon {
    height: 40px;

    i {
      color: #000000;
    }
  }
}

.delete__icon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: transform ease-in;

  &:hover {
    transform: scale(0.9);
  }

  i {
    color: #dc3545;
    font-size: 24px;
  }
}

.action__buttons {
  display: flex;
  justify-content: flex-end;

  .button {
    line-height: 1.5rem;
    padding: 0.75rem 1rem;
    border: none;
    outline: none;
    border-radius: 0.5rem;
  }

  &-addition {
    background-color: #ffffff;
    border: 1px solid #e0e0e0 !important;
    margin-right: 1rem;
    color: #333333;
  }

  &-deletion {
    background-color: #eb5757;
    color: #ffffff;
  }
}

.prepay__input__percent {
  height: 42px;
}
