
@import "../../assets/scss/utils/tab.sass";

p {
  margin-bottom: 0 !important;
}

.release-note {
  font-family: "CraftworkSans", serif;

  &-header {
    display: flex;
    align-items: center;
    gap: 65px;
    justify-content: space-between;

    p {
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
      color: #4b5563;
    }
  }

  &-main {
    display: flex;
    row-gap: 56px;
    flex-direction: column;
  }

  .block-title {
    font-family: CraftworkSans, serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #9ca3af;
    margin-bottom: 0;
  }

  &__select {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 50%;
  }

  &__block {
    display: flex;
    row-gap: 2rem;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-family: "Inter", serif;
      margin: 2rem 0;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4b5563;
        text-transform: capitalize;
      }

      .base-input {
        width: 100%;
        background: white;
        height: 52px;
        border: 2px solid #e5e7eb;

        ::v-deep input {
          background-color: white !important;
        }
      }
    }
  }

  &-footer {
    display: flex;
    gap: 16px;

    .base__button {
      width: 170px;
    }
  }
}

.release-info {
  &-main {
    display: flex;
    flex-direction: column;
    gap: 56px;

    &-block {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-date {
        font-family: "Inter", serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #9ca3af;
      }

      &-release {
        font-family: "CraftworkSans", serif;
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        color: #4b5563;
      }

      &-tag {
        text-transform: capitalize;
        width: max-content;
        padding: 13px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-family: "CraftworkSans", serif;
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;

        &-new {
          color: #16a34a;
          background: #dcfce7;
        }

        &-edited {
          color: #0284c7;
          background: #e0f2fe;
        }
      }
    }
  }

  &-footer {
    display: flex;
    gap: 18px;

    p {
      font-family: "Inter", serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #9ca3af;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;

  .icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .eye__icon {
    background-color: var(--teal-600);
  }

  .edit__icon {
    background-color: var(--violet-600);
  }

  .delete__icon {
    background-color: var(--red-600);
  }
}

@import "@/assets/scss/utils/pagination";

.search__content {
  margin-top: 0;
}

::v-deep .row__head__bottom-border {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .row__body__bottom-border:not(:last-child) {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .table__list {
  min-height: 250px;
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.125rem 1rem;
      vertical-align: middle;

      //&.b-table-sort-icon-left {
      //display: flex;
      //align-items: center;
      //}
    }

    td {
      vertical-align: middle;
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  //background-position: right !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../assets/icons/icon-arrow-up.svg") !important;
}
