
.info-container {
  margin-top: 30px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 150px;
  margin-bottom: 1em;
}

.item-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  color: #4b5563;
  font-family: "Inter", serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  .title {
    display: flex;
    min-width: max-content;
    color: #9ca3af;
  }

  .title-val {
    p {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.display-none {
  display: none;
}
