
::v-deep .app-tabs-content {
  &-header {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    border-top: 6px solid var(--gray-100);
    border-bottom: 6px solid var(--gray-100);
    padding: 24px 0;

    .nav-item {
      min-width: max-content;

      .nav-link {
        padding: 8px 6px;
      }

      .active {
        background-color: transparent;

        .app-tab-title-number {
          background-color: var(--violet-100);
          color: var(--violet-600);
        }

        p {
          color: var(--violet-600);
        }
      }

      &:last-child {
        width: 100%;

        .nav-link {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.app-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;

  &-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    line-height: 20px;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: var(--gray-400);
    background-color: var(--gray-100);
  }

  &-content {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    color: var(--gray-400);
  }

  &-right-icon {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}

.navigation__content {
  display: flex;
  align-items: center;

  .go__back {
    min-width: 56px;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: var(--gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .breadcrumb__content {
    display: inline-flex;
    flex-direction: column;
    margin-left: 1rem;
    font-weight: 600;
    font-size: 14px;
    color: #9ca3af;
  }

  .head {
    font-size: 24px;
    line-height: 28px;
    color: #4b5563;

    .file_name {
      color: var(--violet-600);
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--gray-200);
  font-size: 1rem;

  .row {
    color: var(--gray-400);
    font-family: CraftworkSans, serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.main__section {
  .main__row {
    color: var(--gray-600);
    font-size: 1.2rem;
    font-family: Inter, serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .cell {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 2px solid var(--gray-200);

      &__item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 1rem 0;
      }
    }
  }
}

::v-deep .fixed-table {
  thead,
  tbody {
    tr {
      display: flex;
      border-top: 2px solid var(--gray-100);

      th {
        font-family: CraftworkSans, serif;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        color: var(--gray-400);
        letter-spacing: 1px;
        padding: 21px 16px;
      }

      td {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--gray-600);
        padding: 0.25rem 1rem;
        margin: 18px 0;
        //max-height: 500px;
        //overflow-y: hidden;

        .cell {
          width: 100%;
        }
      }
    }
  }

  thead tr {
    border-top: none;
  }

  .theadKey,
  .tbodyKey {
    width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .theadValue,
  .tbodyValue {
    width: 22rem;
    text-overflow: ellipsis;
    //overflow: hidden;
  }
}

.error__provider {
  color: red;
  font-size: 12px;
}
