
.addition__traffic {
  width: 100%;
  min-height: 18.25rem;
  background-color: #f9fbff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &-button {
    display: block;
    outline: none;
    border: none;
    color: #fff;
    background-color: #41b883;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    cursor: pointer;
  }
}

::v-deep .modal-self {
  width: 90vw;
}

@media (min-width: 576px) {
  ::v-deep .modal-dialog {
    max-width: 1300px;
    margin: 1.75rem auto;
  }
}
