
button {
  margin: 0 !important;
}

.remove__button {
  margin-left: 16px !important;
}

.user__content {
  padding: 2rem;

  .upload__content {
    display: grid;
    place-content: center;
    position: relative;
    cursor: pointer;

    .upload__avatar {
      opacity: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      cursor: pointer;
    }
  }
}

.avatar__content {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;

  .editor__content {
    &-buttons {
      display: flex;
      margin-bottom: 16px;
    }
  }
}

.validation__provider {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
}

.error__provider {
  display: block;
  color: red;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.submit__button {
  background-color: #007bff !important;
  margin-right: 0;
}

.avatar__content-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.75rem;
  height: 6.75rem;
}

.progress__front {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;

  circle {
    fill: none;
    stroke-dasharray: 283;
  }
}

.avatar-wrapper {
  background-color: darkorchid;
  border-radius: 50%;
  box-shadow: 0.5px 0.5px -1px 0 rgba(0, 0, 0, 0.75);
}

.active-loading {
  &::after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
}

.progress__counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  font-weight: bold;
}

.user__form {
  max-width: 720px;
}
