
hr {
  margin-top: -5px;
  background: #f3f4f6;
  border: 2px solid #f3f4f6;
}

::v-deep .ml-5 {
  display: flex;
  justify-content: flex-end;

  button {
    border: none !important;
    background: #7c3aed !important;
  }
}

::v-deep .my-dropdown .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;

  i {
    color: #4b5563;
  }
}

::v-deep .ml-5:hover {
  display: flex;
  justify-content: flex-end;

  button {
    border: none !important;
    background: #7c3aed !important;
  }
}

.payment__content {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-bottom: 6rem;
  margin-top: 50px;
}

h3 {
  font-family: CraftworkSans, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
}
