.b-shadow-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.b-shadow-2 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

.b-shadow-3 {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
}

.b-shadow-4 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.b-shadow-left {
  box-shadow: -16px 0px 64px rgba(0, 0, 0, 0.16), -16px 0px 24px rgba(0, 0, 0, 0.04);
}

.b-shadow-right {
  box-shadow: 16px 0 64px rgba(0, 0, 0, 0.16), 16px 0 24px rgba(0, 0, 0, 0.04);
}