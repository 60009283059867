
.field-information {
  display: flex;
  gap: 1rem;
  font-style: normal;
  align-items: center;

  .fdn-icon-left-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.525rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--violet-100);
  }

  .fdn-info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .fdn-title-wrapper {
    font-family: CraftworkSans, serif;
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--gray-400);
  }

  .fdn-content-wrapper {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--gray-600);
  }
}
