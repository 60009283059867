
.checkout-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 30px;
  color: var(--white);
  border-radius: 28px;
  height: 56px;
  min-width: 102px;

  &-inner {
    padding: 13px 16px;
  }
}

.error__provider {
  color: red;
  display: block;
  font-size: 14px;
}

::v-deep .dropdown-menu {
  z-index: 10000 !important;
}
