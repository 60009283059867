
@import "../../../assets/scss/utils/pagination";
.contract-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}
.no-pointer {
  pointer-events: none;
}
.section-title {
  color: var(--gray-600);
  font-size: 24px;
}
.apartment {
  font-family: Inter;
  border-radius: 32px;
  background-color: var(--gray-100);
  padding: 20px;
  margin-bottom: 10px;
  & .label {
    font-weight: 600;
    color: #9ca3af;
  }
  & .value {
    font-weight: 600;
    color: var(--gray-600);
  }
}
::v-deep .table__list {
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.25rem 1rem;
    }

    td {
      vertical-align: middle;
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

.title {
  font-family: CraftworkSans, serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 28px;
  color: var(--gray-600);
}

.addition__button {
  border: 2px solid var(--gray-100);
  width: 300px;
  border-radius: 10px;
}
