
.bootstrap-select {
  border: none;
  border-radius: 2rem;
  color: var(--gray-600);
  height: 56px;
  padding: 1rem 2.75rem 1rem 1.25rem;
  background: var(--gray-100) url("../../../assets/icons/icon-down.svg") right
    1rem center/24px 24px no-repeat;

  &:focus {
    background-color: var(--gray-100);
    color: var(--gray-600);
  }
}
