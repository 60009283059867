
@import "src/assets/scss/utils/tab";

::v-deep {
  .custom-tab {
    margin-top: 0;
    position: relative;

    .ch-bottom__line {
      display: flex;
      height: 8px;
      width: calc(100% + 6rem);
      position: absolute;
      top: -8px;
      left: -3rem;
      background-color: var(--gray-100);
    }

    .sticky-top {
      position: -webkit-sticky !important; /* Safari */
      position: sticky !important;
      padding-top: 2rem;
      margin-top: -2rem;
      top: 0;
      z-index: 4;
      width: calc(100% + 6rem);
      right: auto !important;
      left: 0 !important;
      height: 100%;
      margin-left: -3rem;
      box-shadow: none;
      background-color: var(--white);
      transition: all 0.3s linear;

      .nav-tabs {
        background-color: var(--white);
        width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;

        &:after {
          content: "";
          display: flex;
          height: 8px;
          width: calc(100% + 3rem);
          position: absolute;
          bottom: 0;
          left: -3rem;
          background-color: var(--gray-100);
        }
      }
    }

    .fixed-top {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
      transition: all 0.3s linear;
    }
  }
}

.ch-apartment-overview {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3rem;
  padding-top: 2rem;
}

.ch-payment-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  .pd-title {
    grid-column: span 2;
  }
}

.ch-v-status-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.icon-circle-wrapper {
  padding: 0.5rem;
  background-color: var(--violet-100);
}

::v-deep .custom-tab .nav-tabs .nav-link.active.nav-active-state-error:after {
  background-color: var(--red-600);
}

.next-button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 2;
}

.ch-single-apartment-overview {
  padding-top: 0 !important;
}
