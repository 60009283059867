
.home__filter__by {
  gap: 1rem;
}

//.filter__date__wrapper {
//  display: flex;
//  width: 26rem;
//
//  .filter__start__date {
//    ::v-deep .mx-input-wrapper {
//      .mx-input {
//        border-right: 2px solid var(--gray-300);
//        border-top-right-radius: 0;
//        border-bottom-right-radius: 0;
//      }
//    }
//  }
//
//  .filter__end__date{
//    ::v-deep .mx-input-wrapper {
//      .mx-input {
//        border-top-left-radius: 0;
//        border-bottom-left-radius: 0;
//      }
//    }
//  }
//}
