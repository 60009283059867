
.output-information {
  border: 3px solid var(--gray-200);
  border-radius: 2rem;
  background-color: white;
  padding: 1rem 1.75rem;
  //padding-left: 1.75rem;
  //padding-right: 1rem;
  font-family: CraftworkSans, serif;

  .property {
    color: var(--gray-400);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 22px;
  }

  .value {
    font-size: 18px;
    color: var(--gray-600) !important;
    line-height: 22px;
  }

  .info-row {
    gap: 16px;

    &-icon {
      background: #ede9fe;
      border-radius: 50%;
      padding: 0 5px 3px;
    }

    &-icon:nth-child(2) {
      background: #7c3aed;
      padding: 2px 5px 5px;
    }
  }

  .info-row:first-child {
    gap: 12px;
  }
}
