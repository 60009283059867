
.apartment-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 2rem;
}

.apartment-card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 24px;
  background: var(--white);
  border: 2px solid var(--gray-200);
  border-radius: 2rem;

  &__title {
    font-family: CraftworkSans, serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: var(--violet-600);
    margin-bottom: 0;
  }

  &__body {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__calc {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__clear {
    display: flex;

    .base__button {
      width: max-content;
    }
  }
}

.calc-inputs {
  display: flex;
  column-gap: 0.5rem;
}

.apartment-item {
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.apartment-label {
  color: var(--gray-400);
  margin-right: 1rem;
}

.apartment-value {
  margin: 0;
  color: var(--gray-600);
}

.base-price-input {
  height: 56px;
  display: flex;
  width: 100%;
  border-radius: 32px;
  flex-direction: column;
  background-color: var(--gray-100);
  padding-left: 20px;
}
