/* TODO:VIOLET COLOR */
.color-violet-50 {
  color: var(--violet-50) !important;
}

.color-violet-100 {
  color: var(--violet-100) !important;
}

.color-violet-200 {
  color: var(--violet-200) !important;
}

.color-violet-300 {
  color: var(--violet-300) !important;
}

.color-violet-400 {
  color: var(--violet-400) !important;
}

.color-violet-500 {
  color: var(--violet-500) !important;
}

.color-violet-600 {
  color: var(--violet-600) !important;
}

.color-violet-700 {
  color: var(--violet-700) !important;
}

.color-violet-800 {
  color: var(--violet-800) !important;
}

.color-violet-900 {
  color: var(--violet-900) !important;
}

/* TODO:GRAY COLOR */
.color-gray-50 {
  color: var(--gray-50) !important;
}

.color-gray-100 {
  color: var(--gray-100) !important;
}

.color-gray-200 {
  color: var(--gray-200) !important;
}

.color-gray-300 {
  color: var(--gray-300) !important;
}

.color-gray-400 {
  color: var(--gray-400) !important;
}

.color-gray-500 {
  color: var(--gray-500) !important;
}

.color-gray-600 {
  color: var(--gray-600) !important;
}

.color-gray-700 {
  color: var(--gray-700) !important;
}

.color-gray-800 {
  color: var(--gray-800) !important;
}

.color-gray-900 {
  color: var(--violet-900) !important;
}

.color-white {
  color: #FFFFFF !important;
}


/* TODO: YELLOW COLOR */
.color-yellow-100 {
  color: var(--yellow-100) !important;
}

.color-yellow-200 {
  color: var(--yellow-200) !important;
}

.color-yellow-300 {
  color: var(--yellow-300) !important;
}

.color-yellow-400 {
  color: var(--yellow-400) !important;
}

.color-yellow-500 {
  color: var(--yellow-500) !important;
}

.color-yellow-600 {
  color: var(--yellow-600) !important;
}

.color-yellow-700 {
  color: var(--yellow-700) !important;
}

.color-yellow-800 {
  color: var(--yellow-900) !important;
}

/* TODO:BACKGROUND GRADIENT COLORS */
.bg-gradient-violet {
  background: linear-gradient(88.25deg, #7C3AED 0%, #818CF8 100%)
}

/* TODO: BACKGROUND COLOR */
.bg-gray-50 {
  background-color: var(--gray-50) !important;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--gray-900) !important;
}

/* TODO: VIOLET COLOR */
.bg-violet-100 {
  background-color: var(--violet-100) !important;
}

.bg-violet-200 {
  background-color: var(--violet-200) !important;
}

.bg-violet-300 {
  background-color: var(--violet-300) !important;
}

.bg-violet-400 {
  background-color: var(--violet-400) !important;
}

.bg-violet-500 {
  background-color: var(--violet-500) !important;
}

.bg-violet-600 {
  background-color: var(--violet-600) !important;
}

.bg-violet-700 {
  background-color: var(--violet-700) !important;
}

.bg-violet-800 {
  background-color: var(--violet-900) !important;
}

/* TODO: RED COLOR */
.bg-red-100 {
  background-color: var(--red-100) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.bg-red-400 {
  background-color: var(--red-400) !important;
}

.bg-red-500 {
  background-color: var(--red-500) !important;
}

.bg-red-600 {
  background-color: var(--red-600) !important;
}

.bg-violet-700 {
  background-color: var(--violet-700) !important;
}

.bg-violet-800 {
  background-color: var(--violet-900) !important;
}


/* TODO: YELLOW COLOR */
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.bg-yellow-800 {
  background-color: var(--yellow-900) !important;
}

.bg-green {
  background-color: green  !important;
}
