
::v-deep .tooltip-inner
  color: #4B5563 !important
  margin-bottom: 0
  padding: 12px
  background: #E5E7EB
  border-radius: 16px

::v-deep .arrow::before
  border-top-color: #E5E7EB !important

.promos
  margin-top: .5rem
  margin-bottom: 1rem

  .promo__section:last-child
    border-bottom: 3px solid var(--gray-100)

.promo__section
  font-family: Inter, sans-serif
  padding-top: 1.5rem
  padding-bottom: 1.5rem
  border-top: 3px solid var(--gray-100)
  font-weight: 600

  &-title
    color: var(--violet-600) !important
    font-weight: 600
    font-size: 18px
    line-height: 24px
    text-transform: capitalize

  &-subtitle
    color: var(--gray-400) !important
    font-weight: 600
    font-size: 14px
    line-height: 20px

.apartment__details
  padding-top: 1rem
  padding-bottom: 1rem

  &-title
    font-family: CraftworkSans, serif
    font-weight: 900
    font-size: 24px
    line-height: 28px
    margin-bottom: 1.5rem
    color: var(--gray-400) !important

  &-row
    font-family: Inter, sans-serif
    font-weight: 600
    display: flex
    justify-content: space-between
    padding: 0.5rem 0

    .property
      color: var(--gray-400)
      text-transform: capitalize

    .value
      color: var(--gray-600)
