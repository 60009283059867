.bottom-clipboard {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto -3rem;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem 2rem 0 0;
  padding: 12px 32px;
  z-index: 9;
  animation: fade-top .5s linear;
  background-color: var(--violet-600);
  color: white;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  @keyframes fade-top {
    0% {
      opacity: 0;
      transform: rotateX(90deg);
      bottom: -10px;
    }
    100% {
      opacity: 1;
      transform: rotateX(0);
      bottom: 0;
    }
  }
}