
.error__provider {
  color: red;
  display: block;
  margin-bottom: 1rem;
}

.save__button {
  color: white;
}

::v-deep .modal-content {
  border: none;
  border-radius: 50px;
  padding: 35px 35px 10px 35px;
  top: -50%;
  left: 50%;
  transform: translate(-50%, 10%);
  min-width: max-content;

  header {
    display: flex;
    align-items: center;
    border: none !important;

    h5 {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 36px;
      color: #4b5563;
    }

    button {
      background: #f3f4f6;
      color: #9ca3af;
      padding: 10px 15px;
      border-radius: 50%;
    }
  }

  .form-control {
    border: none;
    border-radius: 32px;
    width: 400px;
    background: #f3f4f6;
    color: #9ca3af;
    padding: 23px;
    margin-bottom: 6px;
  }

  form {
    div {
      display: flex;
      justify-content: space-between !important;

      button {
        margin-top: 30px;
        color: #4b5563 !important;
        background: #f3f4f6 !important;
        width: 100%;
      }

      button:nth-child(2) {
        background: linear-gradient(
          88.25deg,
          #7c3aed 0%,
          #818cf8 100%
        ) !important;
        color: white;
      }
    }
  }
}
