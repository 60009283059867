
.payments-list {
  margin-bottom: 3rem;
}

::v-deep .row__head__bottom-border {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .row__body__bottom-border:not(:last-child) {
  border-bottom: 2px solid var(--gray-200) !important;
}

::v-deep .table__list {
  min-height: 250px;
  max-height: none;

  table {
    color: var(--gray-600);

    thead tr th {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      color: var(--gray-400) !important;
      padding: 1.125rem 1rem;
      vertical-align: middle;
    }

    td {
      font-family: Inter, sans-serif;
      cursor: pointer;
      vertical-align: middle;
      padding: 21px 16px;
      font-size: 1rem;
      line-height: 22px;
      font-weight: 600;
      color: var(--gray-600);
    }
  }

  .table.b-table[aria-busy="true"] {
    opacity: 1 !important;
  }
}

::v-deep .table.b-table > thead > tr > [aria-sort="none"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-position: right calc(2rem / 2) center !important;
  //background-position: right !important;
  padding-right: 20px;
}

::v-deep .table.b-table > thead > tr > [aria-sort="ascending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../../../assets/icons/icon-arrow-down.svg") !important;
}

::v-deep .table.b-table > thead > tr > [aria-sort="descending"],
::v-deep .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position: right calc(2rem / 2) center !important;
  background-size: 20px;
  background-image: url("../../../../assets/icons/icon-arrow-up.svg") !important;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;

  .base__button {
    width: 32px;
    height: 32px;
    padding: 0.25rem !important;

    ::v-deep .right__icon {
      margin: 0 !important;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

::v-deep .modal-content .main .modal-price-input .price-input {
  padding: 0 !important;
}

.modal-price-input {
  height: 56px;
  display: flex;
  min-width: 229.5px;
  border-radius: 32px;
  flex-direction: column;
  background-color: var(--gray-100);
  padding-left: 20px;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}

.close-btn {
  cursor: pointer;
}
